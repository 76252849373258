import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map, retry, switchMap } from 'rxjs/operators';
import { HCINPortalAPIService } from 'src/app/services';
import {
  ArrivalsActions,
  EArrivalsActions,
  GetArrivalsActivitiesError,
  GetArrivalsActivitiesSuccess,
  GetArrivalsError,
  GetArrivalsSuccess,
  UpdateArrivalsActivity,
  UpdateArrivalsActivityError,
  UpdateArrivalsActivitySuccess
} from './arrivals.actions';
import { Store } from '@ngrx/store';
import { Arrivals, ArrivalsActivity } from '../../common/models/arrivals';
import { accountFeature } from '../account/account.reducers';

@Injectable({
  providedIn: 'root'
})
export class ArrivalsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly _hcinPortalApiService: HCINPortalAPIService,
    private readonly _store: Store
  ) {}

  getArrivals$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ArrivalsActions>(EArrivalsActions.GetArrivals),
      concatLatestFrom(() => this._store.select(accountFeature.selectCanSeeInternational)),
      filter(([, selectCanSeeInternational]) => !!selectCanSeeInternational),
      switchMap(() => {
        return this._hcinPortalApiService.getArrivals().pipe(
          retry(1),
          map((data: Arrivals) => new GetArrivalsSuccess(data)),
          catchError((error: Error) => of(new GetArrivalsError(error)))
        );
      })
    );
  });

  getArrivalsActivities$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ArrivalsActions>(EArrivalsActions.GetArrivalsActivities),
      concatLatestFrom(() => this._store.select(accountFeature.selectCanSeeInternational)),
      filter(([, selectCanSeeInternational]) => !!selectCanSeeInternational),
      switchMap(() => {
        return this._hcinPortalApiService.getArrivalsActivities().pipe(
          retry(1),
          map((data: ArrivalsActivity[]) => new GetArrivalsActivitiesSuccess(data)),
          catchError((error: Error) => of(new GetArrivalsActivitiesError(error)))
        );
      })
    );
  });

  updateArrivalsActivity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ArrivalsActions>(EArrivalsActions.UpdateArrivalsActivity),
      exhaustMap((action: UpdateArrivalsActivity) =>
        this._hcinPortalApiService.updateArrivalsActivity(action.payload).pipe(
          map(() => new UpdateArrivalsActivitySuccess(action.payload)),
          catchError((error: Error) => of(new UpdateArrivalsActivityError(error)))
        )
      )
    );
  });
}
