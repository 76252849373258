import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { AppState, AuthenticationService, VersionService } from './services';
import { QueryParams } from './common';
import { UrlHelper } from './common/UrlHelper';
import { AppInsights } from './shared';
import { NavController } from '@ionic/angular';
import { debounceTime, filter, map, takeUntil } from 'rxjs/operators';
import { Subject, fromEventPattern } from 'rxjs';
import { AppVersionInfo } from './shared/version-retrieval-service';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { AppEntryService } from './shared/app-entry-service';
import { Store } from '@ngrx/store';
import { NotificationService, ThemeManagerService, Themes } from 'hc-design-system-lib';
import { ErrorMessages } from 'src/app/common/error-messages';
import { IsCatchAllRedirect } from 'src/app/common/router-helpers';
import { Capacitor } from '@capacitor/core';
import { ConnectionStatus, ConnectionStatusChangeListener, Network } from '@capacitor/network';
import { accountFeature } from './store/account/account.reducers';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();

  private readonly navEndEvent$ = this.router.events.pipe(
    filter(e => e instanceof NavigationEnd),
    takeUntil(this._destroying$)
  );

  private readonly catchAllRedirect$ = this.router.events.pipe(
    filter(e => e instanceof RoutesRecognized),
    filter((e: RoutesRecognized) => {
      return IsCatchAllRedirect(e);
    }, takeUntil(this._destroying$))
  );

  private networkStatus: ConnectionStatus;
  private networkListener: ConnectionStatusChangeListener;

  canSeeInternational$ = this.store.select(accountFeature.selectCanSeeInternational);

  constructor(
    private readonly appInsights: AppInsights,
    private readonly authService: AuthenticationService,
    private readonly version: VersionService,
    private readonly appState: AppState,
    private readonly appEntryService: AppEntryService,
    private readonly navCtrl: NavController,
    private readonly router: Router,
    private readonly store: Store,
    private readonly ngZone: NgZone,
    private readonly notificationService: NotificationService,
    private readonly themeManagerService: ThemeManagerService
  ) {
    this.navEndEvent$.pipe(takeUntil(this._destroying$)).subscribe((e: NavigationEnd) => {
      const params = UrlHelper.GetCurrentUrlForGAScrubbing();
      const paramString = params.toString();
      const dataLayer = window.dataLayer || [];
      const a = e.url.split('?');
      if (paramString.length > 0) {
        dataLayer.push({ page_path: a[0] + '?' + paramString });
      } else {
        dataLayer.push({ page_path: a[0] });
      }
    });
  }

  async ngOnInit() {
    if (Capacitor.isNativePlatform()) {
      const networkObservable = fromEventPattern<ConnectionStatus>(
        (handler: ConnectionStatusChangeListener) => {
          this.networkListener = handler;
          Network.addListener('networkStatusChange', this.networkListener);
        },
        (_handler: ConnectionStatusChangeListener) => {
          Network.removeAllListeners();
        }
      );
      networkObservable
        .pipe(
          debounceTime(300),
          map(status => {
            this.ngZone.run(() => {
              this.networkStatus = status;
              this.nativeAppNetworkCheck();
            });
          })
        )
        .subscribe(() => {});
      this.networkStatus = await Network.getStatus();
      this.nativeAppNetworkCheck();
    }

    this.version.init();
    // manual changed for now...
    const versionInfo: AppVersionInfo = {
      version: '5.3.1',
      hash: '1'
    };
    this.appEntryService.setInitial(window.location.href);

    this.appInsights.init(<AppVersionInfo>versionInfo);

    const merlinId = UrlHelper.GetMerlinId();
    if (merlinId) {
      sessionStorage.setItem(QueryParams.MERLIN_ID, merlinId);
    }

    this.handlePageNotFoundEvents();
    this.router.initialNavigation();

    this.canSeeInternational$.pipe(takeUntil(this._destroying$)).subscribe(canSeeInternational => {
      if (canSeeInternational) {
        this.themeManagerService.setTheme(Themes.HCIN);
      } else {
        this.themeManagerService.setTheme(Themes.HCTN);
      }
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
    Network.removeAllListeners();
  }

  private async nativeAppNetworkCheck() {
    if (this.networkStatus.connected) {
      if (!(await this.authService.isAuthenticated())) {
        this.appState.isLoaded = false;
        this.appState.setSpinnerState(true);
        this.navCtrl.navigateRoot(['/'], { replaceUrl: true });
      } else {
        this.appState.isLoaded = true;
        this.appState.setSpinnerState(false);
        this.appState.noConnection = true;
      }
      this.appState.noConnection = false;
    } else {
      this.appState.isLoaded = true;
      this.appState.setSpinnerState(false);
      this.appState.noConnection = true;
    }
  }

  private handlePageNotFoundEvents() {
    const pageNotFoundStorageKey = 'pageNotFound';
    this.catchAllRedirect$.pipe(takeUntil(this._destroying$)).subscribe(() => {
      localStorage.setItem(pageNotFoundStorageKey, 'true');

      const userRedirectedForAuth = localStorage.getItem('userRedirectedForAuth');

      if (userRedirectedForAuth === 'true') {
        localStorage.setItem('userRedirectedForAuth', 'false');
      }
    });

    this.navEndEvent$.pipe(takeUntil(this._destroying$)).subscribe((e: NavigationEnd) => {
      const isRedirectRelativeToPath = e?.urlAfterRedirects?.includes(e.url);

      if (!isRedirectRelativeToPath && localStorage.getItem(pageNotFoundStorageKey) === 'true') {
        this.notificationService.showNotification(ErrorMessages.pageNotFound, 'error');

        localStorage.removeItem(pageNotFoundStorageKey);
      }
    });
  }

  loaded() {
    return this.appState.loaded();
  }

  /**
   *
   * @returns true if we have no internet connectivity
   */
  noConnection() {
    return this.appState.noConnection;
  }

  //Used to fire native app checks for network status
  protected async retry() {
    this.networkStatus = await Network.getStatus();
    this.nativeAppNetworkCheck();
  }
}

declare global {
  interface Window {
    dataLayer: any[];
  }
}
