import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { IAppState } from 'src/app/store/app/app.state';
import { AppUrls } from '../app-urls';
import { INAssignment } from '../common';
import { selectJobSubmittalINByJobId, selectJobSubmittalsIN, selectJobSubmittalsINLoading } from '../store/jobs/jobs.selectors';
import { accountFeature } from '../store/account/account.reducers';

@Injectable()
export class SpecificJobGuard implements CanActivate {
  constructor(
    private readonly _router: Router,
    private readonly _store: Store<IAppState>
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | UrlTree {
    const canSeeInternational$: Observable<boolean> = this._store.select(accountFeature.selectCanSeeInternational);
    const accountStateDataLoading$: Observable<boolean> = this._store.select(accountFeature.selectLoading);
    const getAssignmentByJobId$: Observable<INAssignment> = this._store.select(selectJobSubmittalINByJobId(next.params['id']));
    const getAssignmentsINLoading$: Observable<boolean> = this._store.select(selectJobSubmittalsINLoading);
    const getAssignmentsIN$: Observable<INAssignment[]> = this._store.select(selectJobSubmittalsIN);

    return combineLatest([accountStateDataLoading$, canSeeInternational$, getAssignmentByJobId$, getAssignmentsINLoading$, getAssignmentsIN$]).pipe(
      skipWhile(
        ([accountStateDataLoading, canSeeInternational, INAssignmentForJob, getAssignmentsINLoading, getAssignmentsIN]: [
          boolean,
          boolean,
          INAssignment,
          boolean,
          INAssignment[]
        ]) => accountStateDataLoading || (canSeeInternational && (getAssignmentsINLoading || getAssignmentsIN === null))
      ),
      map(
        ([accountStateDataLoading, canSeeInternational, INAssignmentForJob, getAssignmentsINLoading, getAssignmentsIN]: [
          boolean,
          boolean,
          INAssignment,
          boolean,
          INAssignment[]
        ]) => {
          if (canSeeInternational && !INAssignmentForJob) {
            return this._router.parseUrl(`/${AppUrls.DASHBOARD}`);
          }
          return true;
        }
      )
    );
  }
}
