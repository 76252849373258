import { createFeature, createReducer, on } from '@ngrx/store';
import { NurseModel } from 'src/app/common';
import { mapNurseINDataToTN } from 'src/app/common/functions/nurse-helpers';
import { initialUserContextState, IUserContextState } from 'src/app/store/userContext/userContext.state';
import { UserContextActions } from './userContext.actions';

export const userContextFeature = createFeature({
  name: 'userContext',
  reducer: createReducer(
    initialUserContextState,
    on(UserContextActions.nukingState, (): IUserContextState => initialUserContextState),
    on(
      UserContextActions.getUser,
      (state): IUserContextState => ({
        ...state,
        userData: {
          ...state.userData,
          error: null,
          loading: true
        }
      })
    ),
    on(
      UserContextActions.getUserSuccess,
      (state, { payload }): IUserContextState => ({
        ...state,
        userData: {
          data: payload,
          error: null,
          loading: false
        }
      })
    ),
    on(
      UserContextActions.getUserError,
      (state, { error }): IUserContextState => ({
        ...state,
        userData: {
          ...state.userData,
          error,
          loading: false
        }
      })
    ),
    on(
      UserContextActions.getUserHcin,
      (state): IUserContextState => ({
        ...state,
        userDataIN: {
          ...state.userDataIN,
          error: null,
          loading: true
        }
      })
    ),
    on(
      UserContextActions.getUserHcinSuccess,
      (state, { payload }): IUserContextState => ({
        ...state,
        userDataIN: {
          data: payload,
          error: null,
          loading: false
        }
      })
    ),
    on(
      UserContextActions.getUserHcinError,
      (state, { error }): IUserContextState => ({
        ...state,
        userDataIN: {
          ...state.userDataIN,
          error,
          loading: false
        }
      })
    ),
    on(
      UserContextActions.createAdUser,
      (state): IUserContextState => ({
        ...state,
        userAdResult: {
          data: null,
          error: null,
          loading: true
        }
      })
    ),
    on(
      UserContextActions.createAdUserSuccess,
      (state, { payload }): IUserContextState => ({
        ...state,
        userAdResult: {
          data: payload,
          error: null,
          loading: false
        }
      })
    ),
    on(
      UserContextActions.createAdUserError,
      (state, { error }): IUserContextState => ({
        ...state,
        userAdResult: {
          data: null,
          error,
          loading: false
        }
      })
    ),
    on(
      UserContextActions.setUserJobNotificationsActive,
      (state, { payload }): IUserContextState => ({
        ...state,
        userData: {
          ...state.userData,
          data: {
            ...state.userData.data,
            jobNotificationsActive: payload
          }
        }
      })
    ),
    on(
      UserContextActions.getNurse,
      (state): IUserContextState => ({
        ...state,
        nurseData: {
          ...state.nurseData,
          error: null,
          loading: true
        }
      })
    ),
    on(
      UserContextActions.getNurseSuccess,
      (state, { payload }): IUserContextState => ({
        ...state,
        nurseData: {
          data: payload,
          error: null,
          loading: false
        }
      })
    ),
    on(
      UserContextActions.getNurseError,
      (state, { error }): IUserContextState => ({
        ...state,
        nurseData: {
          ...state.nurseData,
          error,
          loading: false
        }
      })
    ),
    on(
      UserContextActions.getInternationalNurse,
      (state): IUserContextState => ({
        ...state,
        nurseINData: {
          ...state.nurseINData,
          error: null,
          loading: true
        }
      })
    ),
    on(
      UserContextActions.getInternationalNurseSuccess,
      (state, { payload }): IUserContextState => ({
        ...state,
        nurseINData: {
          data: mapNurseINDataToTN(payload),
          error: null,
          loading: false
        }
      })
    ),
    on(
      UserContextActions.getInternationalNurseError,
      (state, { error }): IUserContextState => ({
        ...state,
        nurseINData: {
          ...state.nurseINData,
          error,
          loading: false
        }
      })
    ),
    on(
      UserContextActions.setNurseIsNewStatus,
      (state, { payload }): IUserContextState => ({
        ...state,
        nurseData: {
          ...state.nurseData,
          data: {
            ...state.nurseData.data,
            isNew: payload
          } as NurseModel
        }
      })
    ),
    on(
      UserContextActions.submitRegistration,
      (state): IUserContextState => ({
        ...state,
        registrationResult: {
          ...state.registrationResult,
          error: null,
          loading: true
        }
      })
    ),
    on(
      UserContextActions.submitRegistrationSuccess,
      (state, { payload }): IUserContextState => ({
        ...state,
        registrationResult: {
          data: payload,
          error: null,
          loading: false
        },
        nurseData: {
          data: null,
          error: null,
          loading: false
        },
        userData: {
          data: null,
          error: null,
          loading: false
        }
      })
    ),
    on(
      UserContextActions.submitRegistrationError,
      (state, { error }): IUserContextState => ({
        ...state,
        registrationResult: {
          ...state.registrationResult,
          error,
          loading: false
        }
      })
    ),
    on(
      UserContextActions.unlockRegistration,
      (state): IUserContextState => ({
        ...state,
        unlockRegistrationResult: {
          ...state.unlockRegistrationResult,
          error: null,
          loading: true
        }
      })
    ),
    on(
      UserContextActions.unlockRegistrationSuccess,
      (state): IUserContextState => ({
        ...state,
        unlockRegistrationResult: {
          ...state.unlockRegistrationResult,
          error: null,
          loading: false
        }
      })
    ),
    on(
      UserContextActions.unlockRegistrationError,
      (state, { error }): IUserContextState => ({
        ...state,
        unlockRegistrationResult: {
          ...state.unlockRegistrationResult,
          error,
          loading: false
        }
      })
    ),
    on(
      UserContextActions.getRegistration,
      (state): IUserContextState => ({
        ...state,
        registrationGetData: {
          ...state.registrationGetData,
          error: null,
          loading: true
        }
      })
    ),
    on(
      UserContextActions.getRegistrationSuccess,
      (state, { payload }): IUserContextState => ({
        ...state,
        registrationGetData: {
          data: payload,
          error: null,
          loading: false
        }
      })
    ),
    on(
      UserContextActions.getRegistrationError,
      (state, { error }): IUserContextState => ({
        ...state,
        registrationGetData: {
          ...state.registrationGetData,
          error,
          loading: false
        }
      })
    ),
    on(
      UserContextActions.setUserIsNewToFalse,
      (state): IUserContextState => ({
        ...state,
        nurseData: {
          ...state.nurseData,
          data: {
            ...state.nurseData.data,
            isNew: false
          } as NurseModel
        }
      })
    ),
    on(
      UserContextActions.logOutUser,
      (state): IUserContextState => ({
        ...state,
        userData: {
          ...state.userData,
          loading: true
        },
        nurseData: {
          ...state.nurseData,
          loading: true
        }
      })
    ),
    on(
      UserContextActions.logOutUserSuccess,
      (state): IUserContextState => ({
        ...state,
        userData: {
          data: null,
          error: null,
          loading: false
        },
        nurseData: {
          data: null,
          error: null,
          loading: false
        }
      })
    ),
    on(
      UserContextActions.logOutUserError,
      (state, { error }): IUserContextState => ({
        ...state,
        userData: {
          data: null,
          error,
          loading: false
        },
        nurseData: {
          data: null,
          error,
          loading: false
        }
      })
    ),
    on(
      UserContextActions.initializeUser,
      (state): IUserContextState => ({
        ...state,
        userInitialized: {
          ...state.userInitialized,
          loading: true
        }
      })
    ),
    on(
      UserContextActions.initializeUserSuccess,
      (state): IUserContextState => ({
        ...state,
        userInitialized: {
          error: null,
          data: true,
          loading: false
        }
      })
    ),
    on(
      UserContextActions.initializeUserError,
      (state, { error }): IUserContextState => ({
        ...state,
        userInitialized: {
          error,
          data: false,
          loading: false
        }
      })
    ),
    on(
      UserContextActions.setNurseProfilepicture,
      (state, { payload }): IUserContextState => ({
        ...state,
        nurseData: {
          data: {
            ...state.nurseData.data,
            profilePicture: payload
          } as NurseModel,
          error: null,
          loading: false
        },
        nurseINData: {
          data: {
            ...state.nurseINData.data,
            profilePicture: payload
          } as NurseModel,
          error: null,
          loading: false
        }
      })
    ),
    on(
      UserContextActions.setNurseIsVerified,
      (state, { payload }): IUserContextState => ({ ...state, nurseData: { ...state.nurseData, data: { ...state.nurseData.data, isVerified: payload } as NurseModel } })
    ),
    on(UserContextActions.bulkUploadDocuments, (state): IUserContextState => ({ ...state, bulkUploadDocuments: { data: null, error: null, loading: true } })),
    on(UserContextActions.bulkUploadDocumentsSuccess, (state): IUserContextState => ({ ...state, bulkUploadDocuments: { data: null, error: null, loading: false } })),
    on(UserContextActions.bulkUploadDocumentsError, (state, { error }): IUserContextState => ({ ...state, bulkUploadDocuments: { data: null, error, loading: false } })),
    on(UserContextActions.getDocuments, (state): IUserContextState => ({ ...state, documents: { data: null, error: null, loading: true } })),
    on(UserContextActions.getDocumentsSuccess, (state, { payload }): IUserContextState => ({ ...state, documents: { data: payload, error: null, loading: false } })),
    on(UserContextActions.getDocumentsError, (state, { error }): IUserContextState => ({ ...state, documents: { data: null, error, loading: false } })),
    on(UserContextActions.getDocumentsById, (state): IUserContextState => ({ ...state, documentsByID: { data: null, error: null, loading: true } })),
    on(UserContextActions.getDocumentsByIdSuccess, (state, { payload }): IUserContextState => ({ ...state, documentsByID: { data: payload, error: null, loading: false } })),
    on(UserContextActions.getDocumentsByIdError, (state, { error }): IUserContextState => ({ ...state, documentsByID: { data: null, error, loading: false } })),
    on(UserContextActions.contactSelectReset, (state): IUserContextState => ({ ...state, contactSelect: { data: null, error: null, loading: true } })),
    on(UserContextActions.contactSelectResetSuccess, (state, { response }): IUserContextState => ({ ...state, contactSelect: { data: response, error: null, loading: false } })),
    on(UserContextActions.contactSelectResetError, (state, { error }): IUserContextState => ({ ...state, contactSelect: { data: null, error, loading: false } })),
    on(UserContextActions.getContactPreferences, (state): IUserContextState => ({ ...state, contactPreferences: { data: null, error: null, loading: true } })),
    on(
      UserContextActions.getContactPreferencesSuccess,
      (state, { payload }): IUserContextState => ({ ...state, contactPreferences: { data: payload, error: null, loading: false } })
    ),
    on(UserContextActions.getContactPreferencesError, (state, { error }): IUserContextState => ({ ...state, contactPreferences: { data: null, error, loading: false } })),
    on(UserContextActions.updateContactPreferences, (state): IUserContextState => ({ ...state, contactPreferences: { data: null, error: null, loading: true } })),
    on(
      UserContextActions.updateContactPreferencesSuccess,
      (state, { payload }): IUserContextState => ({ ...state, contactPreferences: { data: payload, error: null, loading: false } })
    ),
    on(UserContextActions.updateContactPreferencesError, (state, { error }): IUserContextState => ({ ...state, contactPreferences: { data: null, error, loading: false } })),
    on(UserContextActions.deleteUser, (state): IUserContextState => ({ ...state, deleteUserData: { data: null, error: null, loading: true } })),
    on(UserContextActions.deleteUserSuccess, (state, { payload }): IUserContextState => ({ ...state, deleteUserData: { data: payload, error: null, loading: false } })),
    on(UserContextActions.deleteUserError, (state, { error }): IUserContextState => ({ ...state, deleteUserData: { data: null, error, loading: false } })),
    on(UserContextActions.getProfessionalOverview, (state): IUserContextState => ({ ...state, professionalOverview: { data: null, error: null, loading: true } })),
    on(
      UserContextActions.getProfessionalOverviewSuccess,
      (state, { profileData }): IUserContextState => ({ ...state, professionalOverview: { data: profileData, error: null, loading: false } })
    ),
    on(UserContextActions.getProfessionalOverviewError, (state, { error }): IUserContextState => ({ ...state, professionalOverview: { data: null, error, loading: false } })),
    on(UserContextActions.updateSummaryData, (state): IUserContextState => ({ ...state, updateSummaryData: { data: null, error: null, loading: true } })),
    on(
      UserContextActions.updateSummarySuccess,
      (state, { summaryData }): IUserContextState => ({ ...state, updateSummaryData: { data: summaryData, error: null, loading: false } })
    ),
    on(UserContextActions.updateSummaryError, (state, { error }): IUserContextState => ({ ...state, updateSummaryData: { data: null, error, loading: false } })),
    on(UserContextActions.updateSummaryReset, (state): IUserContextState => ({ ...state, updateSummaryData: { data: null, error: null, loading: false } })),
    on(UserContextActions.getSummaryPrompts, (state): IUserContextState => ({ ...state, summaryPrompts: { data: null, error: null, loading: true } })),
    on(
      UserContextActions.getSummaryPromptsSuccess,
      (state, { summaryPrompts }): IUserContextState => ({ ...state, summaryPrompts: { data: summaryPrompts, error: null, loading: false } })
    ),
    on(UserContextActions.getSummaryPromptsError, (state, { error }): IUserContextState => ({ ...state, summaryPrompts: { data: null, error, loading: false } })),
    on(UserContextActions.sendResetPasswordEmail, (state): IUserContextState => ({ ...state, sendResetPasswordEmailResult: { data: null, error: null, loading: true } })),
    on(
      UserContextActions.sendResetPasswordEmailSuccess,
      (state): IUserContextState => ({ ...state, sendResetPasswordEmailResult: { data: null, error: null, loading: false } })
    ),
    on(
      UserContextActions.sendResetPasswordEmailError,
      (state, { error }): IUserContextState => ({ ...state, sendResetPasswordEmailResult: { data: null, error, loading: false } })
    ),
    on(UserContextActions.getQuestionnaire, (state): IUserContextState => ({ ...state, questionnaire: { data: null, error: null, loading: true } })),
    on(UserContextActions.getQuestionnaireSuccess, (state, { data }): IUserContextState => ({ ...state, questionnaire: { data, error: null, loading: false } })),
    on(UserContextActions.getQuestionnaireError, (state, { error }): IUserContextState => ({ ...state, questionnaire: { data: null, error, loading: false } })),
    on(UserContextActions.updateQuestionnaire, (state): IUserContextState => ({ ...state, updateQuestionnaire: { data: null, error: null, loading: true } })),
    on(
      UserContextActions.updateQuestionnaireSuccess,
      (state, { response, data }): IUserContextState => ({
        ...state,
        updateQuestionnaire: { data: response, error: null, loading: false },
        questionnaire: {
          data,
          loading: false,
          error: undefined
        }
      })
    ),
    on(UserContextActions.updateQuestionnaireError, (state, { error }): IUserContextState => ({ ...state, updateQuestionnaire: { data: null, error, loading: false } })),
    on(UserContextActions.updateQuestionnaireReset, (state): IUserContextState => ({ ...state, updateQuestionnaire: { data: null, error: null, loading: false } })),
    on(UserContextActions.getAboutMe, (state): IUserContextState => ({ ...state, aboutMe: { data: null, error: null, loading: true } })),
    on(UserContextActions.getAboutMeSuccess, (state, { payload }): IUserContextState => ({ ...state, aboutMe: { data: payload, error: null, loading: false } })),
    on(UserContextActions.getAboutMeError, (state, { error }): IUserContextState => ({ ...state, aboutMe: { data: null, error, loading: false } })),
    on(UserContextActions.updateAboutMe, (state): IUserContextState => ({ ...state, updateAboutMeTask: { data: null, error: null, loading: true } })),
    on(UserContextActions.updateAboutMeSuccess, (state, { response }): IUserContextState => ({ ...state, updateAboutMeTask: { data: response, error: null, loading: false } })),
    on(UserContextActions.updateAboutMeError, (state, { error }): IUserContextState => ({ ...state, updateAboutMeTask: { data: null, error, loading: false } })),
    on(UserContextActions.updateAboutMeReset, (state): IUserContextState => ({ ...state, updateAboutMeTask: { data: null, error: null, loading: false } })),
    on(UserContextActions.deleteDocuments, (state): IUserContextState => ({ ...state, documents: { data: null, error: null, loading: true } })),
    on(UserContextActions.deleteDocumentsSuccess, (state): IUserContextState => ({ ...state, documents: { data: null, error: null, loading: false } })),
    on(UserContextActions.deleteDocumentsError, (state, { error }): IUserContextState => ({ ...state, documents: { data: null, error, loading: false } })),
    on(UserContextActions.uploadProfilePicture, (state): IUserContextState => ({ ...state, uploadProfilePicture: { data: null, error: null, loading: true } })),
    on(
      UserContextActions.uploadProfilePictureSuccess,
      (state, { payload }): IUserContextState => ({ ...state, uploadProfilePicture: { data: payload, error: null, loading: false } })
    ),
    on(UserContextActions.uploadProfilePictureError, (state, { error }): IUserContextState => ({ ...state, uploadProfilePicture: { data: null, error, loading: false } })),
    on(UserContextActions.getDocuments, (state): IUserContextState => ({ ...state, documents: { data: null, error: null, loading: true } })),
    on(UserContextActions.getDocumentsSuccess, (state, { payload }): IUserContextState => ({ ...state, documents: { data: payload, error: null, loading: false } })),
    on(UserContextActions.getDocumentsError, (state, { error }): IUserContextState => ({ ...state, documents: { data: null, error, loading: false } })),
    on(UserContextActions.getDocumentsById, (state): IUserContextState => ({ ...state, documentsByID: { data: null, error: null, loading: true } })),
    on(UserContextActions.getDocumentsByIdSuccess, (state, { payload }): IUserContextState => ({ ...state, documentsByID: { data: payload, error: null, loading: false } })),
    on(UserContextActions.getDocumentsByIdError, (state, { error }): IUserContextState => ({ ...state, documentsByID: { data: null, error, loading: false } })),
    on(
      UserContextActions.setShowSpecialtyPopup,
      (state, { showSpecialtyPopup }): IUserContextState => ({ ...state, showSpecialtyPopup: { data: showSpecialtyPopup, error: null, loading: true } })
    ),
    on(UserContextActions.sendVerificationSupportEmail, (state): IUserContextState => ({ ...state, verificationSupportEmailSent: { data: null, error: null, loading: true } })),
    on(UserContextActions.sendVerificationEmailSuccess, (state): IUserContextState => ({ ...state, verificationSupportEmailSent: { data: null, error: null, loading: false } })),
    on(
      UserContextActions.sendVerificationEmailError,
      (state, { error }): IUserContextState => ({ ...state, verificationSupportEmailSent: { data: null, error, loading: false } })
    ),
    on(UserContextActions.sendVerificationSms, (state): IUserContextState => ({ ...state, verificationSmsSent: { data: null, error: null, loading: true } })),
    on(UserContextActions.sendVerificationEmailSuccess, (state): IUserContextState => ({ ...state, verificationSmsSent: { data: null, error: null, loading: false } })),
    on(UserContextActions.sendVerificationEmailError, (state, { error }): IUserContextState => ({ ...state, verificationSmsSent: { data: null, error, loading: false } })),
    on(UserContextActions.setVerificationType, (state): IUserContextState => ({ ...state, verificationTypeResult: { data: null, error: null, loading: true } })),
    on(
      UserContextActions.setVerificationTypeSuccess,
      (state, { response }): IUserContextState => ({ ...state, verificationTypeResult: { data: response, error: null, loading: false } })
    ),
    on(UserContextActions.setVerificationTypeError, (state, { error }): IUserContextState => ({ ...state, verificationTypeResult: { data: null, error, loading: false } })),
    on(UserContextActions.checkVerificationKey, (state): IUserContextState => ({ ...state, verificationCheckResult: { data: null, error: null, loading: true } })),
    on(
      UserContextActions.checkVerificationKeySuccess,
      (state, { response }): IUserContextState => ({ ...state, verificationCheckResult: { data: response, error: null, loading: false } })
    ),
    on(UserContextActions.checkVerificationKeyError, (state, { error }): IUserContextState => ({ ...state, verificationCheckResult: { data: null, error, loading: false } })),
    on(UserContextActions.setIsReferred, (state, { isReferred }): IUserContextState => ({ ...state, isReferred: { data: isReferred, error: null, loading: false } }))
  )
});

export const {
  name,
  reducer,
  selectUserContextState,
  selectAboutMe,
  selectContactPreferences,
  selectDocuments,
  selectDocumentsByID,
  selectNurseData,
  selectNurseINData,
  selectRegistrationGetData,
  selectUserData,
  selectUserDataIN,
  selectBulkUploadDocuments,
  selectProfessionalOverview,
  selectSummaryPrompts,
  selectUpdateSummaryData,
  selectUserInitialized,
  selectUploadProfilePicture,
  selectVerificationCheckResult,
  selectVerificationSmsSent,
  selectVerificationSupportEmailSent,
  selectVerificationTypeResult,
  selectShowSpecialtyPopup,
  selectIsReferred,
  selectContactSelect,
  selectDeleteUserData,
  selectUpdateAboutMeTask,
  selectUpdateQuestionnaire,
  selectUnlockRegistrationResult,
  selectRegistrationResult,
  selectUserAdResult
} = userContextFeature;
