import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { AppUrls } from 'src/app/app-urls';
import { selectNurseData, selectNurseDataLoading } from 'src/app/store/userContext/userContext.selectors';
import { selectDefaultReferralRecruiterData, selectDefaultReferralRecruiterDataLoading } from 'src/app/store/recruiter/recruiter.selectors';
import { IAppState } from 'src/app/store/app/app.state';
import { NurseModel } from 'src/app/common';
import { GetDefaultReferralRecruiter, GetRecruiter } from '../store/recruiter/recruiter.actions';
import { Recruiter } from '../common/models/recruiter';
import { UserContextActions } from '../store/userContext/userContext.actions';

@Injectable()
export class CongratulationsGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _store: Store<IAppState>
  ) {}

  canActivate(): Observable<boolean | UrlTree> | UrlTree {
    this._store.dispatch(new GetRecruiter());

    const defaultRecruiter$: Observable<Recruiter> = this._store.select(selectDefaultReferralRecruiterData);
    const defaultRecruiterLoading$: Observable<boolean> = this._store.select(selectDefaultReferralRecruiterDataLoading);
    const nurseData$: Observable<NurseModel> = this._store.select(selectNurseData);
    const nurseLoadingData$: Observable<boolean> = this._store.select(selectNurseDataLoading);

    return combineLatest([nurseData$, nurseLoadingData$, defaultRecruiter$, defaultRecruiterLoading$]).pipe(
      distinctUntilChanged(),
      map(([nurse, nurseLoading, defaultRecruiter, defaultRecruiterLoading]: [NurseModel, boolean, Recruiter, boolean]) => {
        if (!defaultRecruiterLoading && !defaultRecruiter) {
          this._store.dispatch(new GetDefaultReferralRecruiter());
        }
        if (!nurseLoading && !nurse) {
          this._store.dispatch(UserContextActions.getNurse());
          return true;
        }
        if (nurse?.isNew) {
          return true;
        }
        return this._router.parseUrl(`${AppUrls.DASHBOARD}?recruiterPopup=${nurse?.recruiterId ? true : false}`);
      })
    );
  }
}
