import { DomSanitizer } from '@angular/platform-browser';
import { createSelector } from '@ngrx/store';
import { AvatarBorderColor, AvatarSize } from 'hc-design-system-lib';
import { NurseModel } from 'src/app/common';
import { getInitials } from 'src/app/common/functions/nurse-helpers';
import { IAppState } from 'src/app/store/app/app.state';
import { IUserContextState } from 'src/app/store/userContext/userContext.state';
import { accountFeature } from '../account/account.reducers';

const selectUserContextState = (state: IAppState) => state.userContext;

export const selectUserData = createSelector(selectUserContextState, (state: IUserContextState) => state.userData.data);
export const selectUserDataLoading = createSelector(selectUserContextState, (state: IUserContextState) => state.userData.loading);
export const selectUserDataError = createSelector(selectUserContextState, (state: IUserContextState) => state.userData.error);

export const selectUserDataIN = createSelector(selectUserContextState, (state: IUserContextState) => state.userDataIN.data);
export const selectUserDataINLoading = createSelector(selectUserContextState, (state: IUserContextState) => state.userDataIN.loading);
export const selectUserDataINError = createSelector(selectUserContextState, (state: IUserContextState) => state.userDataIN.error);

export const selectUserImmigration = createSelector(selectUserContextState, (state: IUserContextState) => state.userDataIN?.data?.immigrationRecords);

export const selectUserProfessionSector = createSelector(
  selectUserContextState,
  accountFeature.selectData,
  (state: IUserContextState, account) => state.userData?.data?.professionSector ?? account.professionSector
);

export const selectAdUserResultData = createSelector(selectUserContextState, (state: IUserContextState) => state.userAdResult.data);
export const selectAdUserResultError = createSelector(selectUserContextState, (state: IUserContextState) => state.userAdResult.error);
export const selectAdUserResultLoading = createSelector(selectUserContextState, (state: IUserContextState) => state.userAdResult.loading);

export const selectNurseData = createSelector(selectUserContextState, (state: IUserContextState) => state.nurseData.data);
export const selectNurseDataLoading = createSelector(selectUserContextState, (state: IUserContextState) => state.nurseData.loading);
export const selectNurseDataError = createSelector(selectUserContextState, (state: IUserContextState) => state.nurseData.error);

export const selectNurseINData = createSelector(selectUserContextState, (state: IUserContextState) => state.nurseINData.data);
export const selectNurseINDataLoading = createSelector(selectUserContextState, (state: IUserContextState) => state.nurseINData.loading);
export const selectNurseINDataError = createSelector(selectUserContextState, (state: IUserContextState) => state.nurseINData.error);

export const selectNurseDataForCongratulations = createSelector(selectUserContextState, (state: IUserContextState) => {
  if (state.isReferred?.data && state.nurseData) {
    return { ...state.nurseData.data, isReferred: true } as NurseModel;
  }
  return state.nurseData.data;
});

export const selectNurseAvatarCardDetails = createSelector(
  accountFeature.selectData,
  selectNurseData,
  selectNurseINData,
  (account, nurseTN: NurseModel, nurseIN: NurseModel) => {
    let nurse = account.canSeeTravel ? nurseTN : nurseIN;
    if (nurse) {
      return {
        name: `${nurse.firstName} ${nurse.lastName}`,
        email: nurse.email,
        phone: `${nurse?.mobileNumber?.includes('+1') ? '' : '+1'} ${nurse.mobileNumber}`
      };
    }
  }
);

export const selectNurseAvatarConfiguration = (sanitizer: DomSanitizer) =>
  createSelector(selectNurseData, selectNurseINData, accountFeature.selectData, (nurse: NurseModel, nurseIN: NurseModel, account) => {
    if (nurse) {
      const baseImgSrc = account.canSeeInternational && nurseIN?.profilePicture ? nurseIN.profilePicture : nurse.profilePicture;
      return {
        imageSource: baseImgSrc ? sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + baseImgSrc) : null,
        text: getInitials(nurse),
        size: AvatarSize.Large,
        borderColor: AvatarBorderColor.Gray
      };
    }
  });

export const selectRegistrationSubmitData = createSelector(selectUserContextState, (state: IUserContextState) => state.registrationResult?.data);
export const selectRegistrationSubmitLoading = createSelector(selectUserContextState, (state: IUserContextState) => state.registrationResult?.loading);
export const selectRegistrationSubmitError = createSelector(selectUserContextState, (state: IUserContextState) => state.registrationResult?.error);

export const selectRegistrationGetData = createSelector(selectUserContextState, (state: IUserContextState) => state.registrationGetData?.data);
export const selectRegistrationGetLoading = createSelector(selectUserContextState, (state: IUserContextState) => state.registrationGetData?.loading);
export const selectRegistrationGetError = createSelector(selectUserContextState, (state: IUserContextState) => state.registrationGetData?.error);

export const selectInitializeUserLoading = createSelector(selectUserContextState, (state: IUserContextState) => state.userInitialized?.loading);
export const selectInitializeUserError = createSelector(selectUserContextState, (state: IUserContextState) => state.userInitialized?.error);

export const selectIsReferred = createSelector(selectUserContextState, (state: IUserContextState) => state.isReferred?.data);

export const selectProfessionalOverview = createSelector(selectUserContextState, (state: IUserContextState) => state.professionalOverview?.data);
export const selectProfessionalOverviewLoading = createSelector(selectUserContextState, (state: IUserContextState) => state.professionalOverview?.loading);
export const selectProfessionalOverviewError = createSelector(selectUserContextState, (state: IUserContextState) => state.professionalOverview?.error);

export const selectUpdateSummary = createSelector(selectUserContextState, (state: IUserContextState) => state.updateSummaryData);
export const selectUpdateSummaryData = createSelector(selectUserContextState, (state: IUserContextState) => state.updateSummaryData?.data);
export const selectUpdateSummaryLoading = createSelector(selectUserContextState, (state: IUserContextState) => state.updateSummaryData?.loading);
export const selectUpdateSummaryError = createSelector(selectUserContextState, (state: IUserContextState) => state.updateSummaryData?.error);

export const selectGetSummaryPrompts = createSelector(selectUserContextState, (state: IUserContextState) => state.summaryPrompts?.data);
export const selectGetSummaryPromptsLoading = createSelector(selectUserContextState, (state: IUserContextState) => state.summaryPrompts?.loading);
export const selectGetSummaryPromptsError = createSelector(selectUserContextState, (state: IUserContextState) => state.summaryPrompts?.error);

export const selectProfessionalOverviewFormDataLoading = createSelector(
  selectProfessionalOverviewLoading,
  selectGetSummaryPromptsLoading,
  (professionalOverviewLoading, summaryPromptsLoading) => professionalOverviewLoading && summaryPromptsLoading
);

export const selectSendResetPasswordEmailResult = createSelector(selectUserContextState, (state: IUserContextState) => state.sendResetPasswordEmailResult?.data);
export const selectSendResetPasswordEmailResultLoading = createSelector(selectUserContextState, (state: IUserContextState) => state.sendResetPasswordEmailResult?.loading);
export const selectSendResetPasswordEmailResultError = createSelector(selectUserContextState, (state: IUserContextState) => state.sendResetPasswordEmailResult?.error);

export const selectQuestionnaireData = createSelector(selectUserContextState, (state: IUserContextState) => state.questionnaire?.data);
export const selectQuestionnaireLoading = createSelector(selectUserContextState, (state: IUserContextState) => state.questionnaire?.loading);
export const selectQuestionnaireError = createSelector(selectUserContextState, (state: IUserContextState) => state.questionnaire?.error);

export const selectUpdateQuestionnaire = createSelector(selectUserContextState, (state: IUserContextState) => state.updateQuestionnaire);
export const selectUpdateQuestionnaireData = createSelector(selectUserContextState, (state: IUserContextState) => state.updateQuestionnaire?.data);
export const selectUpdateQuestionnaireLoading = createSelector(selectUserContextState, (state: IUserContextState) => state.updateQuestionnaire?.loading);
export const selectUpdateQuestionnaireError = createSelector(selectUserContextState, (state: IUserContextState) => state.updateQuestionnaire?.error);

export const selectAboutMe = createSelector(selectUserContextState, (state: IUserContextState) => state.aboutMe.data);
export const selectAboutMeLoading = createSelector(selectUserContextState, (state: IUserContextState) => state.aboutMe?.loading);

export const selectUpdateAboutMeTask = createSelector(selectUserContextState, (state: IUserContextState) => state.updateAboutMeTask);
export const selectUpdateAboutMeTaskData = createSelector(selectUserContextState, (state: IUserContextState) => state.updateAboutMeTask.data);
export const selectUpdateAboutMeTaskLoading = createSelector(selectUserContextState, (state: IUserContextState) => state.updateAboutMeTask.loading);

export const selectResumeDocuments = createSelector(selectUserContextState, (state: IUserContextState) =>
  state.documents?.data?.filter(x => x.title.includes('Resume'))?.sort((a, b) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime())
);
export const selectDocuments = createSelector(selectUserContextState, (state: IUserContextState) => state.documents.data);
export const selectDocumentsLoading = createSelector(selectUserContextState, (state: IUserContextState) => state.documents?.loading);

export const selectDocumentsByID = createSelector(selectUserContextState, (state: IUserContextState) => state.documentsByID.data);
export const selectDocumentsByIDLoading = createSelector(selectUserContextState, (state: IUserContextState) => state.documentsByID?.loading);

export const selectShowSpecialityPopup = createSelector(
  selectUserContextState,
  accountFeature.selectData,
  (state: IUserContextState, account) => state.showSpecialtyPopup?.data && !account.canSeeInternational
);

export const selectVerificationSupportEmailLoading = createSelector(selectUserContextState, (state: IUserContextState) => state.verificationSupportEmailSent.loading);

export const selectVerificationEmailSentLoading = createSelector(selectUserContextState, (state: IUserContextState) => state.verificationEmailSent.loading);
export const selectVerificationSmsSentLoading = createSelector(selectUserContextState, (state: IUserContextState) => state.verificationSmsSent.loading);

export const selectVerificationTypeResultData = createSelector(selectUserContextState, (state: IUserContextState) => state.verificationTypeResult.data);
export const selectVerificationTypeResultLoading = createSelector(selectUserContextState, (state: IUserContextState) => state.verificationTypeResult.loading);
export const selectVerificationCheckResultData = createSelector(selectUserContextState, (state: IUserContextState) => state.verificationCheckResult.data);
export const selectVerificationCheckResultError = createSelector(selectUserContextState, (state: IUserContextState) => state.verificationCheckResult.error);
export const selectVerificationCheckResultLoading = createSelector(selectUserContextState, (state: IUserContextState) => state.verificationCheckResult.loading);

export const selectDeleteUserData = createSelector(selectUserContextState, (state: IUserContextState) => state.deleteUserData.data);

export const selectDeleteUserLoading = createSelector(selectUserContextState, (state: IUserContextState) => state.deleteUserData.loading);

export const selectDeleteUserError = createSelector(selectUserContextState, (state: IUserContextState) => state.deleteUserData.error);

export const selectSegmentStaffingName = createSelector(accountFeature.selectCanSeeInternational, accountFeature.selectCanSeeTravel, (canSeeInternational, canSeeTravel) => {
  if (canSeeTravel) {
    return 'HCTN';
  } else if (canSeeInternational) {
    return 'PPUSA';
  } else {
    return '';
  }
});

export const selectContactPreferences = createSelector(selectUserContextState, (state: IUserContextState) => state.contactPreferences?.data);
export const selectContactPreferencesLoading = createSelector(selectUserContextState, (state: IUserContextState) => state.contactPreferences?.loading);

export const selectBulkUploadDocuments = createSelector(selectUserContextState, (state: IUserContextState) => state.bulkUploadDocuments);
export const selectBulkUploadDocumentsLoading = createSelector(selectUserContextState, (state: IUserContextState) => state.bulkUploadDocuments.loading);
export const selectBulkUploadDocumentsError = createSelector(selectUserContextState, (state: IUserContextState) => state.bulkUploadDocuments.error);
