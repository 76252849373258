import { SegmentTrackLinkClickInfo } from 'src/app/shared/segment/track-link-click.directive';

export abstract class VisaDetailURLs {
  public static readonly EMBASSY_INTERVIEW: SegmentTrackLinkClickInfo = {
    url: 'https://travel.state.gov/content/travel/en/us-visas/immigrate/the-immigrant-visa-process/step-1-submit-a-petition/step-2-begin-nvc-processing.html',
    text: 'National Visa Center & Embassy Resources'
  };
  public static readonly USCIS_CASE_STATUS: SegmentTrackLinkClickInfo = { url: 'https://egov.uscis.gov/casestatus/landing.do', text: 'USCIS Case Status' };
  public static readonly USCIS_PROCESSING_TIMES: SegmentTrackLinkClickInfo = { url: 'https://egov.uscis.gov/processing-times/', text: 'USCIS Processing Times' };
}
