import { IAccountStateModel } from 'src/app/common/models/account-state-model';
import { IDataState, initializeData } from '../app/app.models';

export class AccountState implements IDataState<IAccountStateModel> {
  data: IAccountStateModel;
  loading: boolean;
  error: Error;
}

export const initialAccountState: AccountState = initializeData();
