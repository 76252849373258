import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { LinkTarget } from 'hc-design-system-lib';
import { ITileCardConfig } from 'hc-design-system-lib/lib/components/cards/cards.interfaces';
import { Observable, Subject } from 'rxjs';
import { UserContextIN } from 'src/app/common';
import { selectIsMobile } from 'src/app/store/ui/ui.selectors';
import { selectUserDataIN } from 'src/app/store/userContext/userContext.selectors';
import { VisaDetailURLs } from './visa-detail-urls';

@Component({
  selector: 'app-visa-details-tile',
  templateUrl: './visa-details-tile.component.html',
  styleUrls: ['./visa-details-tile.component.scss']
})
export class VisaDetailsTileComponent implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  tileConfig: ITileCardConfig = {
    title: 'Visa Application Details'
  };

  blankLinkTarget = LinkTarget.Blank;

  visaDetailURLs = VisaDetailURLs;

  isMobile$: Observable<boolean> = this._store.select(selectIsMobile);
  nurseIN$: Observable<UserContextIN> = this._store.select(selectUserDataIN);

  constructor(private _store: Store) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openExternalLink(url: string) {
    window.open(url, '_blank');
  }
}
