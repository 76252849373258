import { createSelector } from '@ngrx/store';
import { IAppState } from 'src/app/store/app/app.state';
import { IArrivalsState } from './arrivals.state';

const selectArrivalsState = (state: IAppState) => state.arrivals;

export const selectArrivals = createSelector(selectArrivalsState, (state: IArrivalsState) => state?.arrivals?.data);
export const selectHasArrivals = createSelector(selectArrivalsState, (state: IArrivalsState) => !!state?.arrivals?.data);
export const selectHasFetchedArrivals = createSelector(selectArrivalsState, (state: IArrivalsState) => state?.arrivals?.hasFetched);
export const selectArrivalsLoading = createSelector(selectArrivalsState, (state: IArrivalsState) => state?.arrivals?.loading);
export const selectArrivalsError = createSelector(selectArrivalsState, (state: IArrivalsState) => state?.arrivals?.error);
export const selectArrivalsFlightDetails = createSelector(selectArrivalsState, (state: IArrivalsState) => state?.arrivals?.data?.travelExpenses?.flightDetails);
export const selectArrivalsTransportationDetails = createSelector(selectArrivalsState, (state: IArrivalsState) => state?.arrivals?.data?.travelExpenses?.transportationDetails);
export const selectArrivalsAccommodationDetails = createSelector(selectArrivalsState, (state: IArrivalsState) => state?.arrivals?.data?.travelExpenses?.accommodationDetails);
export const selectArrivalsActivities = createSelector(selectArrivalsState, (state: IArrivalsState) => state?.arrivalsActivities?.data);
export const selectHasArrivalsActivities = createSelector(selectArrivalsState, (state: IArrivalsState) => !!state?.arrivalsActivities?.data);
export const selectHasFetchedArrivalsActivities = createSelector(selectArrivalsState, (state: IArrivalsState) => state?.arrivalsActivities?.hasFetched);
export const selectArrivalsActivitiesLoading = createSelector(selectArrivalsState, (state: IArrivalsState) => state?.arrivalsActivities?.loading);
export const selectUpdateArrivalsActivityIdLoading = createSelector(selectArrivalsState, (state: IArrivalsState) => state?.updateArrivalsActityResult?.data?.id);
