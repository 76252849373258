import { createSelector } from '@ngrx/store';
import { checkIfDateIsAfter } from 'src/app/common/functions/date-manipulations';
import { IAppState } from '../app/app.state';
import { LicensesState } from './licenses.state';
import { License, LicenseIN, LicenseType } from 'src/app/common';
import { accountFeature } from '../account/account.reducers';

const selectLicensesState = (state: IAppState) => state.licenses;

export const selectLicense = createSelector(selectLicensesState, (state: LicensesState) => state.license.data);

export const selectLicenseLoading = createSelector(selectLicensesState, (state: LicensesState) => state.license.loading);

export const selectLicenseError = createSelector(selectLicensesState, (state: LicensesState) => state.license.error);

export const selectLicenseExpired = createSelector(selectLicensesState, (state: LicensesState) => checkIfDateIsAfter(new Date(), state.license.expirationDate));

export const selectLicenseExpirationDate = createSelector(selectLicensesState, (state: LicensesState) => state.license.expirationDate);

export const selectLicenses = createSelector(selectLicensesState, (state: LicensesState) => state.licenses.data);

export const selectLicensesLoading = createSelector(selectLicensesState, (state: LicensesState) => state.licenses.loading);

export const selectLicensesError = createSelector(selectLicensesState, (state: LicensesState) => state.licenses.error);

export const selectLicensesExpired = createSelector(selectLicensesState, (state: LicensesState) => checkIfDateIsAfter(new Date(), state.licenses.expirationDate));

export const selectLicensesExpirationDate = createSelector(selectLicensesState, (state: LicensesState) => state.licenses.expirationDate);

export const selectLicensesIN = createSelector(selectLicensesState, (state: LicensesState) => state.licensesIN.data);

export const selectLicensesINLoading = createSelector(selectLicensesState, (state: LicensesState) => state.licensesIN.loading);

export const selectLicensesINError = createSelector(selectLicensesState, (state: LicensesState) => state.licensesIN.error);

export const selectLicensesINExpired = createSelector(selectLicensesState, (state: LicensesState) => checkIfDateIsAfter(new Date(), state.licensesIN.expirationDate));

export const selectLicensesINExpirationDate = createSelector(selectLicensesState, (state: LicensesState) => state.licensesIN.expirationDate);

export const selectLicensesByUserType = createSelector(
  accountFeature.selectCanSeeTravel,
  accountFeature.selectCanSeeInternational,
  selectLicenses,
  selectLicensesIN,
  (canSeeTravel: boolean, canSeeInternational: boolean, licensesTN: License[], licensesIN: LicenseIN[]) => {
    const licenses: License[] = [];
    if (canSeeTravel && licensesTN) {
      return licensesTN;
    }
    if (canSeeInternational && licensesIN) {
      licenses.push(
        ...licensesIN.map(lI => {
          return {
            ...lI,
            type: null,
            licenseType: lI.type == LicenseType.State ? LicenseType.State : LicenseType.International,
            expirationDate: lI.expirationDate ? new Date(lI.expirationDate) : null,
            issuingDate: new Date(lI.issuingDate)
          };
        })
      );
    }
    return licenses;
  }
);

export const selectLicensesLoadingByUserType = createSelector(
  accountFeature.selectCanSeeTravel,
  accountFeature.selectCanSeeInternational,
  selectLicensesLoading,
  selectLicensesINLoading,
  (canSeeTravel: boolean, canSeeInternational: boolean, licensesTNLoading: boolean, licensesINLoading: boolean) => {
    if (canSeeTravel && canSeeInternational) {
      return licensesINLoading || licensesTNLoading;
    } else if (canSeeInternational) {
      return licensesINLoading;
    } else {
      return licensesTNLoading;
    }
  }
);

export const selectNursysAddResponse = createSelector(selectLicensesState, (state: LicensesState) => state.nursysAdd.data);

export const selectNursysAddLoading = createSelector(selectLicensesState, (state: LicensesState) => state.nursysAdd.loading);

export const selectNursysAddError = createSelector(selectLicensesState, (state: LicensesState) => state.nursysAdd.error);

export const selectNursysUpdateResponse = createSelector(selectLicensesState, (state: LicensesState) => state.nursysUpdate.data);

export const selectNursysUpdateLoading = createSelector(selectLicensesState, (state: LicensesState) => state.nursysUpdate.loading);

export const selectNursysUpdateError = createSelector(selectLicensesState, (state: LicensesState) => state.nursysUpdate.error);

export const selectNursysLoading = createSelector(
  selectNursysAddLoading,
  selectNursysUpdateLoading,
  (addLoading: boolean, updateLoading: boolean) => addLoading || updateLoading
);

export const selectUpdateResponse = createSelector(selectLicensesState, (state: LicensesState) => state.updateResponse.data);

export const selectUpdateResponseLoading = createSelector(selectLicensesState, (state: LicensesState) => state.updateResponse.loading);

export const selectUpdateResponseError = createSelector(selectLicensesState, (state: LicensesState) => state.updateResponse.error);

export const selectFileUrl = createSelector(selectLicensesState, (state: LicensesState) => state.fileUrl.data);
