import { Component, OnDestroy, OnInit } from '@angular/core';
import { Submittal } from 'src/app/common';
import { AssignmentReasonSubStagesArray, PortalStatuses, AssignmentStageConstants } from 'src/app/common/models/submittal-constants';
import { FormHeaderService } from 'src/app/services';
import { Store } from '@ngrx/store';
import { Subscription, Observable, combineLatest } from 'rxjs';
import { IAppState } from 'src/app/store/app/app.state';
import { selectJobSubmittalsByUserType, selectJobSubmittalsLoading } from 'src/app/store/jobs/jobs.selectors';
import { selectToDoApplyTasks } from 'src/app/store/tasks/tasks.selectors';
import { NurseTask } from 'src/app/common/models/db-objects';
import { GetTasks } from 'src/app/store/tasks/tasks.actions';
import { ApplicationsViewed } from 'src/app/store/segment/segment.actions';
import { BodySize } from 'hc-design-system-lib';
import { WEEKLY_RATES_REMINDER } from 'src/app/common/models/job-constants';
import { accountFeature } from 'src/app/store/account/account.reducers';

@Component({
  selector: 'app-new-submittals',
  templateUrl: './new-submittals.component.html',
  styleUrls: ['./new-submittals.component.scss']
})
export class NewSubmittalsComponent implements OnInit, OnDestroy {
  bodySize: BodySize = BodySize.Body;
  weeklyRatesReminder = WEEKLY_RATES_REMINDER;
  submittals: Submittal[] = [];
  submittalsByType = {
    submitted: [],
    inProgress: [],
    withdrawn: [],
    closed: []
  };
  completedTasks: boolean;
  canSeeTravel: boolean;

  submittalsSubscription: Subscription;
  submittals$: Observable<Submittal[]> = this._store.select(selectJobSubmittalsByUserType);

  toDoApplyTasks$: Observable<NurseTask[]> = this._store.select(selectToDoApplyTasks);

  submittalsLoading$: Observable<boolean> = this._store.select(selectJobSubmittalsLoading);

  canSeeTravel$: Observable<boolean> = this._store.select(accountFeature.selectCanSeeTravel);

  constructor(
    public _formHeaderService: FormHeaderService,
    private _store: Store<IAppState>
  ) {}

  ngOnInit() {
    this._store.dispatch(new GetTasks(false, true));
    this.addSubmittalsSubscription();
    this._formHeaderService.resetFormHeaderAttributes({
      title: 'My Applications',
      showBackButton: false
    });
  }

  ngOnDestroy(): void {
    this.submittalsSubscription?.unsubscribe();
  }

  getSubmittalsCount(type): String {
    if (!this.submittalsByType[type]) {
      return '';
    } else {
      return ` (${this.submittalsByType[type].length})`;
    }
  }

  addSubmittalsSubscription(): void {
    this.submittalsSubscription = combineLatest([this.submittals$, this.canSeeTravel$]).subscribe(([submittals, canSeeTravel]) => {
      if (submittals && canSeeTravel != undefined) {
        this.submittals = submittals;
        this.canSeeTravel = canSeeTravel;
        this._store.dispatch(new ApplicationsViewed(this.submittals?.length));
        this.separateSubmittals(this.submittals, this.canSeeTravel);
      }
    });
  }

  separateSubmittals(submittals: Submittal[], canSeeTravel: boolean): void {
    this.submittalsByType = {
      submitted: [],
      inProgress: [],
      withdrawn: [],
      closed: []
    };
    if (canSeeTravel) {
      submittals.forEach(submittal => {
        if (submittal.submittalWithdrawnDate) {
          this.submittalsByType.withdrawn.push(submittal);
        } else if (submittal.matchingInactiveJobOrder) {
          this.submittalsByType.closed.push(submittal);
        } else if (!AssignmentReasonSubStagesArray.includes(submittal.assnSubstage)) {
          if (submittal.assnStage === AssignmentStageConstants.Application) {
            this.submittalsByType.inProgress.push(submittal);
          } else {
            this.submittalsByType.submitted.push(submittal);
          }
        }
      });
    } else {
      submittals.forEach(submittal => {
        switch (submittal.portalStatus) {
          case PortalStatuses.AssignmentComplete:
          case PortalStatuses.OfferDeclined:
          case PortalStatuses.NoLongerAvailable:
            this.submittalsByType.closed.push(submittal);
            break;
          default:
            this.submittalsByType.submitted.push(submittal);
        }
      });
    }
  }
}
