import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import { catchError, exhaustMap, filter, map, retry, skipWhile, switchMap, tap } from 'rxjs/operators';
import { GuestApiService } from 'src/app/services/guest-api.service';
import {
  ESkillsChecklistActions,
  GetCandidateSkill,
  GetCandidateSkillError,
  GetCandidateSkillSuccess,
  GetCandidateSkillsError,
  GetCandidateSkillsSuccess,
  GetNurseApiSkillQuestions,
  GetNurseApiSkillQuestionsError,
  GetNurseApiSkillQuestionsSuccess,
  GetSkill,
  GetSkillDictionaryError,
  GetSkillDictionarySuccess,
  GetSkillError,
  GetSkillSuccess,
  GetSkills,
  GetSkillsError,
  GetSkillsSuccess,
  SkillsChecklistActions,
  UpdateSkillQuestions,
  UpdateSkillQuestionsError,
  UpdateSkillQuestionsSuccess
} from './skillsChecklist.actions';
import { SkillSummary } from 'src/app/common/models/skill-summary';
import { CandidateSkillSummary } from 'src/app/common/models/candidate-skill-summary-model';
import { Store } from '@ngrx/store';
import { NursePortalApi } from 'src/app/services';
import { CandidateSkill } from 'src/app/common/models/candidate-skill';
import { Question } from 'src/app/common/models/question';
import { Skill } from 'src/app/common/models/skill';
import { NotificationService } from 'hc-design-system-lib';
import { selectIsSpecificFlagOn } from '../flags/flags.selectors';
import { featureFlagNames } from 'src/app/services/feature-flags.service';
import { selectGroupLookup, selectQuestionsLookup, selectSkillLookup } from '../lookups/lookups.selectors';
import { AppUserSkill, ILookup } from 'src/app/common';
import { SkillQuestionGroup } from 'src/app/common/models/skill-question-group';
import { SkillQuestion } from 'src/app/common/models/skill-question';
import { CreateCandidateSkillRequest, UpdateCandidateSkillRequest } from 'src/app/common/models/skill-question-form';
import { selectCandidateSkillsData, selectCandidateSkillsError, selectSkillsData, selectSkillsError } from './skillsChecklist.selectors';
import { selectTaskPathsMap } from '../tasks/tasks.selectors';
import { SkillDictionary } from 'src/app/common/models/skill-dictionary';
import { GetVariant } from '../flags/flags.actions';
import { SkillChecklistCompleted } from '../segment/segment.actions';
import { UserContextActions } from '../userContext/userContext.actions';
import { accountFeature } from '../account/account.reducers';

@Injectable({
  providedIn: 'root'
})
export class SkillsChecklistEffects {
  constructor(
    private actions$: Actions,
    private _nursePortalApi: NursePortalApi,
    private _guestApiService: GuestApiService,
    private _store: Store,
    private _notificationService: NotificationService
  ) {}

  getSkillById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<SkillsChecklistActions>(ESkillsChecklistActions.GetSkill),
      switchMap(action =>
        combineLatest([
          of(action),
          this._store.select(selectIsSpecificFlagOn(featureFlagNames.guestServiceSkillsChecklist)).pipe(skipWhile(useGuestServices => useGuestServices == null))
        ])
      ),
      switchMap(([action, useGuestServiceSkills]: [GetSkill, boolean]) => {
        this._store.dispatch(new GetVariant(featureFlagNames.guestServiceSkillsChecklist));
        if (useGuestServiceSkills) {
          return this._guestApiService.getSkillById(action.payload).pipe(
            retry(1),
            map((skill: Skill) => new GetSkillSuccess(skill)),
            catchError((error: Error) => of(new GetSkillError(error)))
          );
        } else {
          return combineLatest([
            this._store.select(selectSkillLookup),
            this._store.select(selectGroupLookup),
            this._store.select(selectQuestionsLookup),
            this._nursePortalApi.getQuestions('' + action.payload)
          ]).pipe(
            retry(1),
            map(([skillLookup, groupLookup, questionsLookup, questions]: [Map<number, ILookup<string>>, Map<number, any>, Map<number, Question>, Question[]]) => {
              const skill = skillLookup.get(action.payload);
              const questionGroups = questions.reduce((arr, q) => {
                const question = {
                  id: q.id,
                  versionId: q.versionId,
                  questionId: q.questionId,
                  text: questionsLookup.get(q.questionId).text,
                  score: q.score
                } as SkillQuestion;

                const existing = arr.find(g => g.groupId === q.groupId);
                if (existing) {
                  existing.questions.push(question);
                } else {
                  arr.push({
                    groupId: q.groupId,
                    text: groupLookup.get(q.groupId).text,
                    questions: [question]
                  } as SkillQuestionGroup);
                }

                return arr;
              }, [] as SkillQuestionGroup[]);

              return new GetSkillSuccess({
                skillId: action.payload,
                name: skill.name,
                isActive: true,
                isLatest: true,
                version: questions[0].versionId,
                questionGroups: questionGroups
              } as Skill);
            }),
            catchError((error: Error) => of(new GetSkillError(error)))
          );
        }
      })
    );
  });

  getSkills$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<SkillsChecklistActions>(ESkillsChecklistActions.GetSkills, UserContextActions.checkVerificationKeySuccess),
      switchMap(action =>
        combineLatest([
          of(action),
          this._store.select(selectIsSpecificFlagOn(featureFlagNames.guestServiceSkillsChecklist)).pipe(skipWhile(useGuestServices => useGuestServices == null)),
          this._store.select(selectSkillLookup).pipe(skipWhile(skillLookup => !skillLookup))
        ])
      ),
      switchMap(([_, useGuestServiceSkills, skillLookup]: [GetSkills, boolean, Map<number, ILookup<string>>]) => {
        this._store.dispatch(new GetVariant(featureFlagNames.guestServiceSkillsChecklist));
        if (useGuestServiceSkills) {
          return this._guestApiService.getSkills().pipe(
            retry(1),
            map((data: SkillSummary[]) => new GetSkillsSuccess(data)),
            catchError((error: Error) => of(new GetSkillsError(error)))
          );
        } else {
          return of(
            new GetSkillsSuccess(
              Array.from(skillLookup.values()).map(
                v =>
                  ({
                    skillId: parseInt(v.id),
                    version: null,
                    name: v.name,
                    isActive: (v as any).isInactive,
                    isLatest: null
                  }) as SkillSummary
              )
            )
          );
        }
      })
    );
  });

  getGuestCandidateSkillById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<SkillsChecklistActions>(ESkillsChecklistActions.GetCandidateSkill),
      exhaustMap((action: GetCandidateSkill) =>
        this._guestApiService.getCandidateSkillById(action.payload).pipe(
          retry(1),
          map((candidateSkill: CandidateSkill) => new GetCandidateSkillSuccess(candidateSkill)),
          catchError((error: Error) => of(new GetCandidateSkillError(error)))
        )
      )
    );
  });

  getCandidateSkills$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<SkillsChecklistActions>(ESkillsChecklistActions.GetCandidateSkills, UserContextActions.checkVerificationKeySuccess),
      switchMap(action =>
        combineLatest([
          of(action),
          this._store.select(selectIsSpecificFlagOn(featureFlagNames.guestServiceSkillsChecklist)).pipe(skipWhile(useGuestServices => useGuestServices == null)),
          this._store.select(accountFeature.selectData).pipe(skipWhile(accountState => !accountState))
        ])
      ),
      switchMap(([_action, useGuestServiceSkills, accountState]) => {
        this._store.dispatch(new GetVariant(featureFlagNames.guestServiceSkillsChecklist));
        if (useGuestServiceSkills) {
          return this._guestApiService.getCandidateSkills(accountState.email).pipe(
            retry(1),
            map((data: CandidateSkillSummary[]) => {
              const candidateSkillMap = new Map(data?.map((i): [number, CandidateSkillSummary] => [i.skillId, i]));
              return new GetCandidateSkillsSuccess(candidateSkillMap);
            }),
            catchError((error: Error) => of(new GetCandidateSkillsError(error)))
          );
        } else {
          return combineLatest([this._nursePortalApi.getUserSkills(), this._store.select(selectSkillLookup)]).pipe(
            retry(1),
            map(([data, skillLookup]: [AppUserSkill[], Map<number, ILookup<string>>]) => {
              const candidateSkillMap = new Map(
                data?.map((i): [number, CandidateSkillSummary] => {
                  const scoreSplit = i.score?.split('/');
                  const answeredQuestions = scoreSplit ? parseInt(scoreSplit[0]) : null;
                  const availableQuestions = scoreSplit ? parseInt(scoreSplit[1]) : null;
                  return [
                    i.skillId,
                    {
                      id: '' + i.id,
                      skillId: i.skillId,
                      skillName: skillLookup.get(i.skillId).name,
                      skillVersion: i.skillVersionId,
                      startDate: i.startDate,
                      endDate: i.endDate,
                      updatedOn: null,
                      answeredQuestions: answeredQuestions,
                      availableQuestions: availableQuestions
                    } as CandidateSkillSummary
                  ];
                })
              );
              return new GetCandidateSkillsSuccess(candidateSkillMap);
            }),
            catchError((error: Error) => of(new GetCandidateSkillsError(error)))
          );
        }
      })
    );
  });

  getSkillDictionary$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<SkillsChecklistActions>(ESkillsChecklistActions.GetSkillDictionary),
      switchMap(() =>
        combineLatest([
          this._store.select(selectCandidateSkillsData),
          this._store.select(selectSkillsData),
          this._store.select(selectTaskPathsMap),
          this._store.select(selectCandidateSkillsError),
          this._store.select(selectSkillsError)
        ])
      ),
      filter(
        ([candidateSkillsData, skillList, selectTaskPathsMap, candidateSkillsError, skillListError]) =>
          !!(candidateSkillsData || candidateSkillsError) && !!(skillList || skillListError) && !!selectTaskPathsMap
      ),
      switchMap(([candidateSkillsMap, skillList, requiredSkillsMap, candidateSkillsError, skillListError]) => {
        if (candidateSkillsError || skillListError) {
          return of(new GetSkillDictionaryError(candidateSkillsError || skillListError));
        }

        const skillDictionary = new SkillDictionary(candidateSkillsMap, skillList, requiredSkillsMap);

        return of(new GetSkillDictionarySuccess(skillDictionary));
      }),
      catchError((error: Error) => of(new GetSkillDictionaryError(error)))
    );
  });

  saveSkillQuestions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<SkillsChecklistActions>(ESkillsChecklistActions.UpdateSkillQuestions),
      switchMap(action => combineLatest([of(action), this._store.select(selectIsSpecificFlagOn(featureFlagNames.guestServiceSkillsChecklist))])),
      skipWhile(([_action, useGuestServices]) => useGuestServices == null),
      exhaustMap(([action, useGuestServiceSkills]: [UpdateSkillQuestions, boolean]) => {
        this._store.dispatch(new GetVariant(featureFlagNames.guestServiceSkillsChecklist));
        if (useGuestServiceSkills) {
          if (action.candidateSkillId) {
            return this._guestApiService
              .updateCandidateSkill(
                {
                  signature: action.skillForm.signature,
                  questionGroups: [...action.skillForm.questionGroups]
                } as UpdateCandidateSkillRequest,
                action.candidateSkillId,
                action.skillForm.email
              )
              .pipe(
                retry(1),
                map(response => new UpdateSkillQuestionsSuccess(response)),
                catchError((err: Error) => of(new UpdateSkillQuestionsError(err)))
              );
          } else {
            return this._guestApiService.createCandidateSkill(action.skillForm as CreateCandidateSkillRequest).pipe(
              retry(1),
              map(response => new UpdateSkillQuestionsSuccess(response)),
              catchError((err: Error) => of(new UpdateSkillQuestionsError(err)))
            );
          }
        } else {
          return this._nursePortalApi
            .savequestionnaire(
              action.skillForm.questionGroups.reduce(
                (arr: Question[], group: SkillQuestionGroup) =>
                  arr.concat(
                    group.questions.map(
                      skillQuestion =>
                        ({
                          id: skillQuestion.id,
                          versionId: skillQuestion.versionId,
                          questionId: skillQuestion.questionId,
                          score: skillQuestion.score
                        }) as Question
                    )
                  ),
                []
              ),
              action.skillVersion,
              action.skillForm.signature
            )
            .pipe(
              retry(1),
              tap(() => {
                const incompleteQuestion = action.skillForm.questionGroups?.find(qg => qg.questions.filter(q => q.score === null)?.length > 0);
                if (!incompleteQuestion) {
                  this._store.dispatch(new SkillChecklistCompleted(action.skillForm));
                }
              }),
              map(
                _ =>
                  new UpdateSkillQuestionsSuccess({
                    id: ''
                  } as CandidateSkill)
              ),
              catchError((err: Error) => of(new UpdateSkillQuestionsError(err)))
            );
        }
      })
    );
  });
  saveSkillQuestionsSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<SkillsChecklistActions>(ESkillsChecklistActions.UpdateSkillQuestionsSuccess),
        tap(() => this._notificationService.showNotification('Your progress was saved.', 'success'))
      );
    },
    { dispatch: false }
  );
  saveSkillQuestionsError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<SkillsChecklistActions>(ESkillsChecklistActions.UpdateSkillQuestionsError),
        tap(() => this._notificationService.showNotification('Your progress could not be saved. Please try again.', 'error'))
      );
    },
    { dispatch: false }
  );

  // To be removed in the future when the Guest User Skills feature flag is no longer needed
  getNurseApiSkillQuestions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<SkillsChecklistActions>(ESkillsChecklistActions.GetNurseApiSkillQuestions),
      exhaustMap((action: GetNurseApiSkillQuestions) =>
        this._nursePortalApi.getQuestions(action.payload).pipe(
          retry(1),
          map((questions: Question[]) => new GetNurseApiSkillQuestionsSuccess(questions)),
          catchError((error: Error) => of(new GetNurseApiSkillQuestionsError(error)))
        )
      )
    );
  });
}
