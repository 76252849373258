import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NotificationService } from 'hc-design-system-lib';
import { combineLatest, from, of } from 'rxjs';
import { catchError, exhaustMap, filter, map, retry, skipWhile, switchMap, take, tap } from 'rxjs/operators';
import {
  AboutMeModel,
  CandidateQualification,
  FileUploadTargets,
  HcinContactModel,
  IAdUserModel,
  IAdUserResponseModel,
  IFileUploadOptions,
  IRegistrationModel,
  IRegistrationResponseModel,
  IResetPasswordModel,
  IUserModel,
  NurseModel,
  NurseProfileModel,
  PreEmploymentQuestionnaire,
  TaskCompletionReturnObject,
  UserContextIN
} from 'src/app/common';
import { VerificationType } from 'src/app/common/constants';
import { updateINContactDataWithAboutMe } from 'src/app/common/functions/nurse-helpers';
import { categoryNames } from 'src/app/common/models/documents';
import {
  applicationNavItem,
  jobsNavItem,
  onAssignmentNavItem,
  profileNavItem,
  settingsNavItem,
  tabBarDefaultItems
} from 'src/app/components/main-components/main/main.constants';
import { AuthenticationService, DocumentHelperService, HCINPortalAPIService, NavHelper, NursePortalApi } from 'src/app/services';
import { UsersApiService } from 'src/app/services/users-api.service';
import { ECertificationsActions, GetCertifications } from 'src/app/store/certifications/certifications.actions';
import { EEducationActions, ResetEducationExpiryDate } from 'src/app/store/education/education.actions';
import { ELicensesActions, ResetLicenseExpiryDate } from 'src/app/store/licenses/licenses.actions';
import { ETasksActions } from 'src/app/store/tasks/tasks.actions';
import { ETechnologyActions } from 'src/app/store/technologies/technology.actions';
import { AccountActions } from '../account/account.actions';
import { accountFeature } from '../account/account.reducers';
import { AngularticsEventTrack } from '../angulartics2/angulartics2.actions';
import { selectProfessionalHierarchy, selectShowSpecialtyPopup } from '../lookups/lookups.selectors';
import { setTabBarItems } from '../ui/ui.actions';
import { selectIsMobile } from '../ui/ui.selectors';
import { EWorkHistoryContextActions } from '../workHistory/workHistoryContext.actions';
import { UserContextActions } from './userContext.actions';
import { selectNurseData, selectNurseINData, selectQuestionnaireData, selectUserData, selectUserProfessionSector } from './userContext.selectors';

@Injectable({
  providedIn: 'root'
})
export class UserContextEffects {
  constructor(
    private actions$: Actions,
    private _usersApiService: UsersApiService,
    private _nursePortalApi: NursePortalApi,
    private _hcinPortalApi: HCINPortalAPIService,
    private _notificationService: NotificationService,
    private store: Store,
    public _auth: AuthenticationService,
    private _documentHelper: DocumentHelperService,
    private _navHelper: NavHelper
  ) {}

  getAccountStateError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.getStateError),
      filter(action => (action.error as HttpErrorResponse).status === 404),
      map(() => UserContextActions.initializeUser())
    );
  });

  getUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        UserContextActions.getUser,
        UserContextActions.submitRegistrationSuccess,
        UserContextActions.setVerificationTypeSuccess,
        UserContextActions.checkVerificationKeySuccess
      ),
      exhaustMap(() =>
        this._usersApiService.getUserState().pipe(
          retry(1),
          map((userData: IUserModel) => UserContextActions.getUserSuccess(userData)),
          catchError((error: Error) => of(UserContextActions.getUserError(error)))
        )
      )
    );
  });

  getUserIN$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.getStateSuccess),
      filter(response => response.payload.canSeeInternational),
      exhaustMap(() =>
        this._hcinPortalApi.getUser().pipe(
          retry(1),
          map((userData: UserContextIN) => UserContextActions.getUserHcinSuccess(userData)),
          catchError((error: Error) => of(UserContextActions.getUserHcinError(error)))
        )
      )
    );
  });

  setTabBarItems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.getNurseSuccess, AccountActions.getStateSuccess),
      concatLatestFrom(() => [this.store.select(selectIsMobile), this.store.select(selectNurseData), this.store.select(accountFeature.selectData)]),
      map(([action, isMobile, nurseData, account]) => {
        let toolbarItems = tabBarDefaultItems;
        if (account.canSeeTravel) {
          // TN nurses will see jobs and applications
          toolbarItems = [...toolbarItems, jobsNavItem, applicationNavItem];
        } else {
          // Non-TN nurses will see profile and applications
          toolbarItems = [...toolbarItems, profileNavItem, applicationNavItem];
        }

        if (nurseData && nurseData.hasAssignment) {
          // Nurses with TimeMobile will see on assignment
          toolbarItems = [...toolbarItems, onAssignmentNavItem];
        }

        if (!isMobile) {
          // Desktop users will see settings mobile users can access it via the profile menu
          toolbarItems = [...toolbarItems, settingsNavItem];
        }
        return setTabBarItems({ tabBarItems: [...toolbarItems] });
      })
    );
  });

  createAdUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.createAdUser),
      map(action => action.payload),
      exhaustMap((input: IAdUserModel) =>
        this._usersApiService.createAdUser(input).pipe(
          map((payload: IAdUserResponseModel) => UserContextActions.createAdUserSuccess(payload)),
          catchError((error: Error) => of(UserContextActions.createAdUserError(error)))
        )
      )
    );
  });

  getNurse$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        UserContextActions.getNurse,
        UserContextActions.submitRegistrationSuccess,
        UserContextActions.updateSummarySuccess,
        UserContextActions.updateAboutMeSuccess,
        UserContextActions.setVerificationTypeSuccess,
        UserContextActions.checkVerificationKeySuccess
      ),
      exhaustMap(() =>
        this._usersApiService.getNurseState().pipe(
          retry(1),
          map((nurseData: NurseModel) => {
            return UserContextActions.getNurseSuccess(nurseData);
          }),
          catchError((error: Error) => of(UserContextActions.getNurseError(error)))
        )
      )
    );
  });

  getNurseIN$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.getInternationalNurse, UserContextActions.updateSummarySuccess, UserContextActions.updateAboutMeSuccess),
      concatLatestFrom(() => this.store.select(accountFeature.selectCanSeeInternational)),
      exhaustMap(([, canSeeInternational]) => {
        if (!canSeeInternational) {
          return of(UserContextActions.getInternationalNurseSuccess(null));
        }
        return this._hcinPortalApi.getNurseState().pipe(
          retry(1),
          map((nurseData: HcinContactModel) => {
            return UserContextActions.getInternationalNurseSuccess(nurseData);
          }),
          catchError((error: Error) => of(UserContextActions.getInternationalNurseError(error)))
        );
      })
    );
  });

  contactSelectReset$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.contactSelectReset),
      exhaustMap((input: any) =>
        this._nursePortalApi.contactSelectReset(input).pipe(
          map((contactSelectResetData: any) => UserContextActions.contactSelectResetSuccess(contactSelectResetData)),
          catchError((error: Error) => of(UserContextActions.contactSelectResetError(error)))
        )
      )
    );
  });

  submitRegistration$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.submitRegistration),
      map(action => action.payload),
      exhaustMap((input: IRegistrationModel) =>
        this._usersApiService.postRegisterV2(input).pipe(
          map((payload: IRegistrationResponseModel) => UserContextActions.submitRegistrationSuccess(payload)),
          catchError((error: Error) => of(UserContextActions.submitRegistrationError(error)))
        )
      )
    );
  });

  unlockRegistration$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.unlockRegistration),
      exhaustMap(() =>
        this._nursePortalApi.unlockRegistationLock().pipe(
          map(() => UserContextActions.unlockRegistrationSuccess()),
          catchError((error: Error) => of(UserContextActions.unlockRegistrationError(error)))
        )
      )
    );
  });

  getRegistration$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.getRegistration),
      exhaustMap(() =>
        this._usersApiService.getRegistration().pipe(
          map((payload: IRegistrationModel) => UserContextActions.getRegistrationSuccess(payload)),
          catchError((error: Error) => of(UserContextActions.getRegistrationError(error)))
        )
      )
    );
  });

  resetUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.resetUser),
      exhaustMap(() =>
        this._nursePortalApi.resetUser().pipe(
          map((response: any) => UserContextActions.resetUserSuccess(response)),
          catchError((error: Error) => of(UserContextActions.resetUserError(error)))
        )
      )
    );
  });

  postSignIn$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.postSignIn),
      exhaustMap(() =>
        this._usersApiService.postSignIn().pipe(
          map((response: any) => UserContextActions.postSignInSuccess(response)),
          catchError((error: Error) => of(UserContextActions.postSignInError(error)))
        )
      )
    );
  });

  clearCache$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.clearCache, UserContextActions.checkVerificationKeySuccess),
      exhaustMap(() =>
        this._nursePortalApi.clearCache().pipe(
          map((response: any) => UserContextActions.clearCacheSuccess(response)),
          catchError((error: Error) => of(UserContextActions.clearCacheError(error)))
        )
      )
    );
  });

  clearCacheLogOut$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.clearCacheAndLogOut, AccountActions.getStateError, UserContextActions.initializeUserError),
      exhaustMap(() =>
        this._nursePortalApi.clearCache().pipe(
          map((response: any) => UserContextActions.clearCacheAndLogOutSuccess(response)),
          catchError((error: Error) => of(UserContextActions.clearCacheAndLogOutError(error)))
        )
      )
    );
  });

  logOut$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.clearCacheAndLogOutSuccess),
      exhaustMap(() =>
        from(
          this._auth.logout().then(x => {
            return x;
          })
        ).pipe(
          map((response: any) => UserContextActions.logOutUserSuccess(response)),
          catchError((error: Error) => of(UserContextActions.logOutUserError(error)))
        )
      )
    );
  });

  // TODO: why are we reloading? Moved this logic over from main.component.ts
  reloadWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.reloadWindow, UserContextActions.resetUserSuccess),
      tap(() => window.location.reload())
    );
  });

  initializeUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.initializeUser),
      exhaustMap(() =>
        this._usersApiService.initializeUser().pipe(
          retry(2),
          map((response: HttpResponse<{ email: string }>) => UserContextActions.initializeUserSuccess(response.status === 201, response.body.email)),
          catchError((error: Error) => of(UserContextActions.initializeUserError(error)))
        )
      )
    );
  });

  getProfessionalOverview$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.getProfessionalOverview, UserContextActions.updateSummarySuccess, UserContextActions.checkVerificationKeySuccess),
      exhaustMap(() =>
        this._nursePortalApi.getProfessionalOverview().pipe(
          retry(2),
          map((professionalOverviewData: NurseProfileModel) => UserContextActions.getProfessionalOverviewSuccess(professionalOverviewData)),
          catchError((error: Error) => of(UserContextActions.getProfessionalOverviewError(error)))
        )
      )
    );
  });

  updateSummary$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.updateSummaryData),
      exhaustMap(action =>
        this._nursePortalApi.updateSummary(action.summaryData).pipe(
          retry(2),
          map((summaryModel: TaskCompletionReturnObject) => {
            const model: any = {
              completionId: summaryModel.completionId,
              returnValue: summaryModel.returnValue,
              Userspeciality: action.summaryData.userSpecialty,
              UserSubSpeciality: action.summaryData.userSubSpecialty,
              UserProfession: action.summaryData.userProfession
            };
            return UserContextActions.updateSummarySuccess(model);
          }),
          catchError((error: Error) => of(UserContextActions.updateSummaryError(error)))
        )
      )
    );
  });

  updateSummarySuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.updateSummarySuccess),
      tap(() => this._notificationService.showNotification('Your response was saved successfully!', 'success')),
      map(() => UserContextActions.updateSummaryReset())
    );
  });

  updateSummaryError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserContextActions.updateSummaryError),
        tap(() => {
          this._notificationService.showNotification(`We were unable to save your changes. Please try again.`, 'error');
        })
      );
    },
    { dispatch: false }
  );

  getSummaryPrompts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.getSummaryPrompts, UserContextActions.checkVerificationKeySuccess),
      exhaustMap(() =>
        this._nursePortalApi.getSummaryPrompts().pipe(
          retry(2),
          map((summaryPrompts: string[]) => UserContextActions.getSummaryPromptsSuccess(summaryPrompts)),
          catchError((error: Error) => of(UserContextActions.getSummaryPromptsError(error)))
        )
      )
    );
  });

  getAboutMe$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.getAboutMe),
      exhaustMap(() =>
        this._nursePortalApi.getAboutMe().pipe(
          retry(1),
          map((aboutMeData: AboutMeModel) => UserContextActions.getAboutMeSuccess(aboutMeData)),
          catchError((error: Error) => of(UserContextActions.getAboutMeError(error)))
        )
      )
    );
  });

  sendResetPasswordEmail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.sendResetPasswordEmail),
      map(action => action.payload),
      exhaustMap((input: IResetPasswordModel) =>
        this._usersApiService.sendResetPasswordEmail(input).pipe(
          map(() => UserContextActions.sendResetPasswordEmailSuccess()),
          catchError((error: Error) => of(UserContextActions.sendResetPasswordEmailError(error)))
        )
      )
    );
  });

  updateAboutMe$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.updateAboutMe),
      concatLatestFrom(() => [this.store.select(accountFeature.selectCanSeeInternational), this.store.select(selectNurseINData)]),
      exhaustMap(([action, canSeeInternational, nurseINData]) =>
        (canSeeInternational
          ? this._hcinPortalApi.updateAboutMe(updateINContactDataWithAboutMe(action.payload, nurseINData))
          : this._usersApiService.updateAboutMe(action.payload)
        ).pipe(
          retry(2),
          map((returnObj: TaskCompletionReturnObject) => UserContextActions.updateAboutMeSuccess(returnObj)),
          catchError((error: Error) => of(UserContextActions.updateAboutMeError(error)))
        )
      )
    );
  });

  updateAboutMeSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.updateAboutMeSuccess),
      tap(() => this._notificationService.showNotification('Your profile changes have been successfully saved.', 'success')),
      map(() => UserContextActions.updateAboutMeReset())
    );
  });

  updateAboutMeError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserContextActions.updateAboutMeError),
        tap(action => this._notificationService.showNotification(`Failed to save: ${action.error}`, 'error'))
      );
    },
    { dispatch: false }
  );

  uploadProfilePicture$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.uploadProfilePicture),
      concatLatestFrom(() => [this.store.select(selectNurseData), this.store.select(selectNurseINData), this.store.select(accountFeature.selectCanSeeInternational)]),
      exhaustMap(([action, nurse, nurseIN, canSeeInternational]) => {
        const options: IFileUploadOptions = {
          itemId: canSeeInternational ? nurseIN.merlinId : nurse.merlinId,
          target: FileUploadTargets.ProfilePicture
        };

        // HCIN API doesn't return the file, so we need to convert the uploaded file to base64 and use it instead of the response object
        if (canSeeInternational) {
          return this._hcinPortalApi.fileUpload(options, action.files).pipe(
            retry(2),
            switchMap(() => this._documentHelper.convertBlobToBase64(action.files[0])),
            map(returnObj => {
              let encoded = (returnObj as string).replace(/^data:(.*,)?/, '');
              if (encoded.length % 4 > 0) {
                encoded += '='.repeat(4 - (encoded.length % 4));
              }
              return UserContextActions.uploadProfilePictureSuccess(encoded);
            }),
            catchError((error: Error) => of(UserContextActions.uploadProfilePictureError(error)))
          );
        }

        return this._nursePortalApi.fileUpload(options, action.files).pipe(
          retry(2),
          map(returnObj => {
            return UserContextActions.uploadProfilePictureSuccess(returnObj);
          }),
          catchError((error: Error) => of(UserContextActions.uploadProfilePictureError(error)))
        );
      })
    );
  });

  uploadProfilePictureSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.uploadProfilePictureSuccess),
      tap(() => this._notificationService.showNotification('Your profile picture was successfully saved.', 'success')),
      map(action => UserContextActions.setNurseProfilepicture(action.payload))
    );
  });

  uploadProfilePictureError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserContextActions.uploadProfilePictureError),
        tap(action => this._notificationService.showNotification(`There was an error updating your profile picture. ${action.error}`, 'error'))
      );
    },
    { dispatch: false }
  );

  getQuestionnaire$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.getQuestionnaire, UserContextActions.checkVerificationKeySuccess),
      exhaustMap(() =>
        this._nursePortalApi.getQuestionnaire().pipe(
          retry(2),
          map((data: PreEmploymentQuestionnaire) => UserContextActions.getQuestionnaireSuccess(data)),
          catchError((error: Error) => of(UserContextActions.getQuestionnaireError(error)))
        )
      )
    );
  });

  updateQuestionnaire$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.updateQuestionnaire),
      concatLatestFrom(() => this.store.select(selectQuestionnaireData)),
      exhaustMap(([action, questionnaire]) => {
        const data = { ...action.data, id: questionnaire.id };
        return this._nursePortalApi.updateQuestionnaire(data).pipe(
          retry(2),
          map((returnData: any) => UserContextActions.updateQuestionnaireSuccess(returnData, data)),
          catchError((error: Error) => of(UserContextActions.updateQuestionnaireError(error)))
        );
      })
    );
  });

  updateQuestionnaireSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.updateQuestionnaireSuccess),
      tap(() => this._notificationService.showNotification('Your background questionnaire was saved successfully!', 'success')),
      map(() => UserContextActions.updateQuestionnaireReset())
    );
  });

  updateQuestionnaireSuccessTrack$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.updateQuestionnaireSuccess),
      map(() => new AngularticsEventTrack('Completed', 'Complete Profile', 'Completed Questionnaire'))
    );
  });

  updateQuestionnaireError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserContextActions.updateQuestionnaireError),
        tap(() => {
          this._notificationService.showNotification(`We were unable to save your changes. Please try again.`, 'error');
        })
      );
    },
    { dispatch: false }
  );

  getDocuments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        UserContextActions.getDocuments,
        EWorkHistoryContextActions.UploadResumeSuccess,
        EWorkHistoryContextActions.AddWorkHistorySuccess,
        EWorkHistoryContextActions.UpdateWorkHistorySuccess,
        EWorkHistoryContextActions.DeleteWorkHistorySuccess,
        ETasksActions.UploadFluVaccineSuccess,
        ETasksActions.UploadCovidVaccineSuccess,
        ECertificationsActions.AddCertificationSuccess,
        ECertificationsActions.UpdateCertificationSuccess,
        ECertificationsActions.DeleteCertificationSuccess,
        ECertificationsActions.AddCertificationBlsSuccess,
        ETechnologyActions.AddTechnologySuccess,
        ETechnologyActions.UpdateTechnologySuccess,
        ETechnologyActions.DeleteTechnologySuccess,
        ELicensesActions.DeleteLicenseSuccess,
        ELicensesActions.CreateLicenseSuccess,
        ELicensesActions.UpdateLicenseSuccess,
        EEducationActions.AddEducationSuccess,
        EEducationActions.UpdateEducationSuccess,
        EEducationActions.DeleteEducationSuccess,
        UserContextActions.deleteDocumentsSuccess,
        UserContextActions.checkVerificationKeySuccess,
        UserContextActions.bulkUploadDocumentsSuccess
      ),
      exhaustMap(() =>
        this._nursePortalApi.getMyDocuments().pipe(
          retry(1),
          map((documentData: CandidateQualification[]) => UserContextActions.getDocumentsSuccess(documentData)),
          catchError((error: Error) => of(UserContextActions.getDocumentsError(error)))
        )
      )
    );
  });

  deleteDocuments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.deleteDocuments),
      exhaustMap(item =>
        this._nursePortalApi.setDocumentToInactive(item.payload.id).pipe(
          retry(1),
          map(() => UserContextActions.deleteDocumentsSuccess(item.payload)),
          catchError((error: Error) => of(UserContextActions.deleteDocumentsError(error)))
        )
      )
    );
  });

  getDocumentsByID$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.getDocumentsById),
      exhaustMap(item =>
        this._nursePortalApi.getDocument(item.payload.id).pipe(
          retry(1),
          map((documentData: Response) => {
            this._documentHelper.downloadFile(documentData, item.payload.fileDownloadUrl);
            return UserContextActions.getDocumentsByIdSuccess(documentData);
          }),
          catchError((error: Error) => of(UserContextActions.getDocumentsByIdError(error)))
        )
      )
    );
  });

  bulkUploadDocuments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.bulkUploadDocuments),
      concatLatestFrom(() => [this.store.select(accountFeature.selectCanSeeInternational)]),
      switchMap(([action, canSeeInternational]) => {
        const options: IFileUploadOptions = {
          target: FileUploadTargets.Bulk
        };
        return this._documentHelper._getDocumentObservable(options, action.payload.files, canSeeInternational).pipe(
          filter(response => !!response),
          take(1),
          switchMap(_response => of(UserContextActions.bulkUploadDocumentsSuccess())),
          catchError(error => of(UserContextActions.bulkUploadDocumentsError(error)))
        );
      })
    );
  });

  deleteDocumentSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserContextActions.deleteDocumentsSuccess),
        tap(action => {
          switch (action.payload.category) {
            case categoryNames.LicenseAndCertifications:
              this.store.dispatch(new GetCertifications(true));
              this.store.dispatch(new ResetLicenseExpiryDate());
              break;
            case categoryNames.BackgroundAndVerifications:
            case categoryNames.Employment:
              this.store.dispatch(new ResetEducationExpiryDate());
          }
        })
      );
    },
    { dispatch: false }
  );

  checkForSpeciality$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.getNurseSuccess),
      map(action => action.payload),
      switchMap((payload: NurseModel) =>
        combineLatest([of(payload), this.store.select(selectUserData), this.store.select(selectProfessionalHierarchy), this.store.select(selectUserProfessionSector)])
      ),
      skipWhile(([nurse, userData, professionalHierarchy]) => !nurse?.professionId || !userData || !professionalHierarchy),
      exhaustMap(([nurse, , , sector]) => {
        return this.store.select(selectShowSpecialtyPopup(nurse.professionId, nurse.specialtyId, sector)).pipe(
          exhaustMap(showPopup => {
            return of(UserContextActions.setShowSpecialtyPopup(showPopup));
          })
        );
      })
    );
  });

  sendVerificationEmail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.sendVerificationEmail),
      concatLatestFrom(() => this.store.select(selectNurseData)),
      exhaustMap(([action, nurseData]) => {
        if (action.resend) {
          return this._nursePortalApi.resendVerifyEmail().pipe(
            map(() => UserContextActions.sendVerificationEmailSuccess(nurseData.email)),
            catchError((error: Error) => of(UserContextActions.sendVerificationEmailError(error)))
          );
        } else {
          return this._nursePortalApi.sendVerifyEmail().pipe(
            map(() => UserContextActions.sendVerificationEmailSuccess(nurseData.email)),
            catchError((error: Error) => of(UserContextActions.sendVerificationEmailError(error)))
          );
        }
      })
    );
  });
  sendVerificationEmailSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserContextActions.sendVerificationEmailSuccess),
        tap(action => {
          this._notificationService.showNotification(`We sent a verification email to ${action.userEmail}. Please check your inbox.`, 'success');
        })
      );
    },
    { dispatch: false }
  );

  sendVerificationSms$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.sendVerificationSms),
      concatLatestFrom(() => this.store.select(selectNurseData)),
      exhaustMap(([action, nurseData]) => {
        if (action.resend) {
          return this._nursePortalApi.resendVerifySMS().pipe(
            map((response: number) => UserContextActions.sendVerificationSmsSuccess(nurseData.mobileNumber, response, action.resend)),
            catchError((error: Error) => of(UserContextActions.sendVerificationSmsError(error)))
          );
        } else {
          return this._nursePortalApi.sendVerifySMS().pipe(
            map((response: number) => UserContextActions.sendVerificationSmsSuccess(nurseData.mobileNumber, response)),
            catchError((error: Error) => of(UserContextActions.sendVerificationSmsError(error)))
          );
        }
      })
    );
  });
  sendVerificationSmsSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserContextActions.sendVerificationSmsSuccess),
        filter(action => !(action.resend && action.response === 2)),
        tap(action => this._notificationService.showNotification(`We sent a verification code to ${action.userPhoneNumber}. Please check your inbox.`, 'success'))
      );
    },
    { dispatch: false }
  );
  resendVerificationSmsExceeded$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.sendVerificationSmsSuccess),
      filter(action => action.resend && action.response === 2),
      tap(() => this._notificationService.showNotification(`You've exceeded resend requests. Please continue verifying through email.`, 'error')),
      map(() => UserContextActions.setVerificationType(VerificationType.Email))
    );
  });
  sendVerificationSmsError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.sendVerificationSmsError),
      map(_action => UserContextActions.setVerificationType(VerificationType.Email))
    );
  });

  setVerificationType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.setVerificationType),
      exhaustMap(action =>
        this._usersApiService.setVerificationType(action.verificationType).pipe(
          retry(2),
          map((returnData: any) => UserContextActions.setVerificationTypeSuccess(returnData, action.verificationType)),
          catchError((error: Error) => of(UserContextActions.setVerificationTypeError(error)))
        )
      )
    );
  });
  setVerificationTypeSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserContextActions.setVerificationTypeSuccess),
        filter(action => action.verificationType === 1),
        tap(_action => this._navHelper.goToVerifyUserEmail())
      );
    },
    { dispatch: false }
  );

  checkVerificationKey$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.checkVerificationKey),
      exhaustMap(action =>
        this._usersApiService.checkVerificationKey(action.verificationKey).pipe(
          retry(2),
          map((returnData: any) => UserContextActions.checkVerificationKeySuccess(returnData)),
          catchError((error: Error) => of(UserContextActions.checkVerificationKeyError(error)))
        )
      )
    );
  });
  checkVerificationKeySuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.checkVerificationKeySuccess),
      map(_action => {
        this._navHelper.goToJobPreferences(true);
        return UserContextActions.setNurseIsVerified(true);
      })
    );
  });
  checkVerificationKeyError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.checkVerificationKeyError),
      map(_action => {
        this._notificationService.showNotification('Invalid Code', 'error');
        return UserContextActions.setNurseIsVerified(false);
      })
    );
  });

  sendVerificationSupportEmail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.sendVerificationSupportEmail),
      exhaustMap(action =>
        this._nursePortalApi.sendVerifySupportReport(action.supportReport).pipe(
          map(() => UserContextActions.sendVerificationSupportEmailSuccess()),
          catchError((error: Error) => of(UserContextActions.sendVerificationSupportEmailError(error)))
        )
      )
    );
  });
  sendVerificationSupportEmailSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserContextActions.sendVerificationSupportEmailSuccess),
        tap(_action => {
          this._notificationService.showNotification('Your message has been successfully sent.', 'success');
        })
      );
    },
    { dispatch: false }
  );

  deleteUserRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.deleteUserRequested),
      exhaustMap(() =>
        this._usersApiService.requestDeleteUser().pipe(
          map(resp => UserContextActions.deleteUserRequestSuccess(resp)),
          catchError((error: unknown) => of(UserContextActions.deleteUserRequestError(error as HttpErrorResponse)))
        )
      )
    );
  });

  deleteUserConfirm$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.deleteUser),
      exhaustMap(action =>
        this._usersApiService.confirmDeleteUser(action.payload).pipe(
          map(resp => UserContextActions.deleteUserSuccess(resp)),
          catchError((error: unknown) => of(UserContextActions.deleteUserError(error as HttpErrorResponse)))
        )
      )
    );
  });

  getContactPreferences$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.getContactPreferences),
      exhaustMap(() =>
        this._usersApiService.getCommunicationPreferences().pipe(
          map(resp => {
            return UserContextActions.getContactPreferencesSuccess(resp);
          }),
          catchError((error: unknown) => of(UserContextActions.getContactPreferencesError(error as HttpErrorResponse)))
        )
      )
    );
  });

  updateContactPreferences$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserContextActions.updateContactPreferences),
      exhaustMap(action =>
        this._usersApiService.postCommunicationPreferences(action.payload).pipe(
          map(resp => {
            return UserContextActions.updateContactPreferencesSuccess(resp);
          }),
          catchError((error: unknown) => of(UserContextActions.updateContactPreferencesError(error as HttpErrorResponse)))
        )
      )
    );
  });
  updateContactPreferencesSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserContextActions.updateContactPreferencesSuccess),
        tap(_action => {
          this._notificationService.showNotification('Your contact preferences have been saved.', 'success');
        })
      );
    },
    { dispatch: false }
  );
  updateContactPreferencesError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserContextActions.updateContactPreferencesError),
        tap(_action => {
          this._notificationService.showNotification('Your contact preferences have not been saved. Please try again.', 'error');
        })
      );
    },
    { dispatch: false }
  );
}
