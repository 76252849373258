import { Action } from '@ngrx/store';
import { Arrivals, ArrivalsActivity } from '../../common/models/arrivals';

export enum EArrivalsActions {
  GetArrivals = '[ARRIVALS] get arrivals',
  GetArrivalsSuccess = '[ARRIVALS] get arrivals success',
  GetArrivalsError = '[ARRIVALS] get arrivals error',
  GetArrivalsActivities = '[ARRIVALS] get arrivals activities',
  GetArrivalsActivitiesSuccess = '[ARRIVALS] get arrivals activities success',
  GetArrivalsActivitiesError = '[ARRIVALS] get arrivals activities error',
  UpdateArrivalsActivity = '[ARRIVALS] update arrivals activity',
  UpdateArrivalsActivitySuccess = '[ARRIVALS] update arrivals activity success',
  UpdateArrivalsActivityError = '[ARRIVALS] update arrivals activity error'
}

export class GetArrivals implements Action {
  public readonly type = EArrivalsActions.GetArrivals;
}

export class GetArrivalsSuccess implements Action {
  public readonly type = EArrivalsActions.GetArrivalsSuccess;

  constructor(public payload: Arrivals) {}
}

export class GetArrivalsError implements Action {
  public readonly type = EArrivalsActions.GetArrivalsError;

  constructor(public error: Error) {}
}

export class GetArrivalsActivities implements Action {
  public readonly type = EArrivalsActions.GetArrivalsActivities;
}

export class GetArrivalsActivitiesSuccess implements Action {
  public readonly type = EArrivalsActions.GetArrivalsActivitiesSuccess;

  constructor(public payload: ArrivalsActivity[]) {}
}

export class GetArrivalsActivitiesError implements Action {
  public readonly type = EArrivalsActions.GetArrivalsActivitiesError;

  constructor(public error: Error) {}
}

export class UpdateArrivalsActivity implements Action {
  public readonly type = EArrivalsActions.UpdateArrivalsActivity;

  constructor(public payload: ArrivalsActivity) {}
}

export class UpdateArrivalsActivitySuccess implements Action {
  public readonly type = EArrivalsActions.UpdateArrivalsActivitySuccess;

  constructor(public response: ArrivalsActivity) {}
}

export class UpdateArrivalsActivityError implements Action {
  public readonly type = EArrivalsActions.UpdateArrivalsActivityError;

  constructor(public error: Error) {}
}

export type ArrivalsActions =
  | GetArrivals
  | GetArrivalsSuccess
  | GetArrivalsError
  | GetArrivalsActivities
  | GetArrivalsActivitiesSuccess
  | GetArrivalsActivitiesError
  | UpdateArrivalsActivity
  | UpdateArrivalsActivitySuccess
  | UpdateArrivalsActivityError;
