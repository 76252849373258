import { Directive, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ExternalLinkClicked, InternalLinkClicked } from 'src/app/store/segment/segment.actions';
import { environment } from 'src/environments/environment';

/* 
  Applying this directive to an element will cause it to listen for a click event on the element and make a request to segment.
    - The segmentEventType input is required otherwise no track request will be made to segment.
    - segmentEventType = 'Internal' will submit a 'Internal Link Clicked' event to segment.
    - segmentEventType = 'External' will submit a 'External Link Clicked' event to segment.

  SegmentTrackLinkClickDirective verified to work with the following elements:
    - hc-link
    - a
*/
export type SegmentTrackLinkClickType = 'Internal' | 'External';
export interface SegmentTrackLinkClickInfo {
  url: string;
  text: string;
}
@Directive({
  selector: '[segmentTrackLinkClick]'
})
export class SegmentTrackLinkClickDirective {
  @Input() segmentEventType: SegmentTrackLinkClickType;

  @Input() segmentEventInfo: SegmentTrackLinkClickInfo;

  @HostListener('click', ['$event']) onClick(_$event: any) {
    this.trackLinkClicked(this.segmentEventType);
  }

  constructor(
    private router: Router,
    private _store: Store
  ) {}

  trackLinkClicked(segmentEventType: 'Internal' | 'External'): void {
    if (segmentEventType) {
      const currentRoute = environment.appBaseUrl + this.router.url;

      switch (segmentEventType) {
        case 'External': {
          this._store.dispatch(new ExternalLinkClicked(currentRoute, this.segmentEventInfo.text, this.segmentEventInfo.url));
          break;
        }
        case 'Internal': {
          this._store.dispatch(new InternalLinkClicked(currentRoute, this.segmentEventInfo.text, this.segmentEventInfo.url));
          break;
        }
        default:
          break;
      }
    }
  }
}
