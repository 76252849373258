import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, skipWhile } from 'rxjs/operators';
import { defer, forkJoin, of } from 'rxjs';
import { Certification, FileUploadTargets, HcinCertification, HcinFileUploadTargets, MapHcinCertificateToHctn, MapHctnCertificateToHcin } from 'src/app/common';
import { DocumentHelperService, HCINPortalAPIService, NursePortalApi } from 'src/app/services';
import {
  ECertificationsActions,
  CertificationsActions,
  GetCertificationsSuccess,
  GetCertificationsError,
  GetCertificationById,
  GetCertificationPopulate,
  GetCertificationPopulateError,
  GetCertificationPopulateSuccess,
  AddCertification,
  AddCertificationSuccess,
  AddCertificationError,
  GetCertifications,
  UpdateCertification,
  UpdateCertificationSuccess,
  DeleteCertification,
  DeleteCertificationSuccess,
  DeleteCertificationError,
  AddCertificationFile,
  AddCertificationFileError,
  UpdateCertificationFileError
} from './certifications.actions';
import { Store } from '@ngrx/store';
import { IAppState } from '../app/app.state';
import { selectCertificationActionRequestId, selectCertifications, selectCertificationsExpiration } from './certifications.selectors';
import { checkIfDateIsAfter, getDateInFutureWithMinutes } from 'src/app/common/functions/date-manipulations';
import { accountFeature } from '../account/account.reducers';

@Injectable({
  providedIn: 'root'
})
export class CertificationsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly _api: NursePortalApi,
    private readonly _hcinApi: HCINPortalAPIService,
    private readonly _documentHelperService: DocumentHelperService,
    private readonly _store: Store<IAppState>
  ) {}

  getCertifications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<CertificationsActions>(ECertificationsActions.GetCertifications),
      map((action: GetCertifications) => action.forceUpdate),
      concatLatestFrom(() => [
        this._store.select(selectCertifications),
        this._store.select(selectCertificationsExpiration),
        this._store.select(accountFeature.selectCanSeeInternational)
      ]),
      exhaustMap(([forceUpdate, certifications, expirationDate, canSeeInternational]) => {
        const isAfter = checkIfDateIsAfter(new Date(), expirationDate);
        if (forceUpdate || isAfter) {
          if (canSeeInternational) {
            return this._hcinApi.getCertifications().pipe(
              map((hcinCerts: HcinCertification[]) => {
                let certs = hcinCerts.map(MapHcinCertificateToHctn);
                return new GetCertificationsSuccess({
                  certifications: certs,
                  expirationDate: getDateInFutureWithMinutes(10)
                });
              }),
              catchError((error: Error) => of(new GetCertificationsError(error)))
            );
          }

          return this._api.getCertifications().pipe(
            map((certs: Certification[]) => {
              return new GetCertificationsSuccess({
                certifications: certs,
                expirationDate: getDateInFutureWithMinutes(10)
              });
            }),
            catchError((error: Error) => of(new GetCertificationsError(error)))
          );
        } else {
          return of(
            new GetCertificationsSuccess({
              certifications,
              expirationDate
            })
          );
        }
      })
    );
  });

  getCertificationPopulate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<CertificationsActions>(ECertificationsActions.GetCertificationPopulate),
      map((action: GetCertificationPopulate) => action.input),
      exhaustMap(certificationId => {
        if (certificationId) {
          return this._api.getCertificationPopulate(certificationId).pipe(
            map(prepopulatedFields => new GetCertificationPopulateSuccess(prepopulatedFields)),
            catchError((error: Error) => of(new GetCertificationPopulateError(error)))
          );
        } else {
          return of(new GetCertificationPopulateSuccess({}));
        }
      })
    );
  });

  addCertification$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<CertificationsActions>(ECertificationsActions.AddCertification),
      map((action: AddCertification) => action.payload),
      concatLatestFrom(() => [this._store.select(accountFeature.selectCanSeeInternational)]),
      exhaustMap(([payload, canSeeInternational]) => {
        if (canSeeInternational) {
          const hcinCert = MapHctnCertificateToHcin(payload.model);

          // certID is an empty GUID only for IELTS certs, which we want to add as a language proficiency.
          return (hcinCert.isIelts ? this._hcinApi.addLanguageProficiency(hcinCert) : this._hcinApi.addCertification(hcinCert)).pipe(
            map(addCertificationResponse => {
              return new AddCertificationFile({
                model: addCertificationResponse,
                files: payload.files,
                isProfessionalCert: hcinCert.isProfessionalCert,
                isIelts: hcinCert.isIelts
              });
            }),
            catchError((error: Error) => of(new AddCertificationError(error)))
          );
        } else {
          return this._api.addCertification(payload.model).pipe(
            map(addCertificationResponse => {
              return new AddCertificationFile({
                model: addCertificationResponse,
                files: payload.files,
                isProfessionalCert: false,
                isIelts: false
              });
            }),
            catchError((error: Error) => of(new AddCertificationError(error)))
          );
        }
      })
    );
  });

  addCertificationFile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<CertificationsActions>(ECertificationsActions.AddCertificationFile),
      map((action: AddCertificationFile) => action.payload),
      concatLatestFrom(() => [this._store.select(accountFeature.selectCanSeeInternational)]),
      exhaustMap(([{ model, files, isProfessionalCert, isIelts }, canSeeInternational]) => {
        // HCIN returns just a string, while HCTN returns a nested object.
        const itemId = model?.returnValue?.id ?? model;

        // Setting target type as HCIN and HCTN use slightly different values
        let targetType: FileUploadTargets | HcinFileUploadTargets = FileUploadTargets.Certification;
        if (canSeeInternational && isProfessionalCert) {
          targetType = HcinFileUploadTargets.ProfessionalCertification;
        } else if (canSeeInternational && isIelts) {
          targetType = HcinFileUploadTargets.LanguageProficiency;
        }

        return this._documentHelperService
          ._getDocumentObservable(
            {
              itemId: itemId,
              target: targetType
            },
            files,
            canSeeInternational
          )
          .pipe(
            map(result => new AddCertificationSuccess(model)),
            catchError(err => of(new AddCertificationFileError({ model: model }, err)))
          );
      })
    );
  });

  addCertificationSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<CertificationsActions>(
        ECertificationsActions.AddCertificationSuccess,
        ECertificationsActions.AddCertificationFileError,
        ECertificationsActions.UpdateCertificationSuccess,
        ECertificationsActions.UpdateCertificationFileError,
        ECertificationsActions.DeleteCertificationSuccess
      ),
      map(() => new GetCertifications(true))
    );
  });

  refreshCertification$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<CertificationsActions>(ECertificationsActions.UpdateCertificationSuccess),
      map((action: UpdateCertificationSuccess) => action.payload),
      concatLatestFrom(() => this._store.select(selectCertificationActionRequestId)),
      skipWhile(([payload, requestId]) => !requestId),
      exhaustMap(([payload, requestId]) => of(new GetCertificationById(requestId)))
    );
  });

  updateCertification$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<CertificationsActions>(ECertificationsActions.UpdateCertification),
      map((action: UpdateCertification) => action.updateCertificationModel),
      exhaustMap(({ model, isFileUpdated, isDeletingDocument, frontFile, backFile }) =>
        forkJoin({
          certification: this._api.updateCertification(model),
          document: defer(() => {
            if (isFileUpdated) {
              return this._documentHelperService._getDocumentObservable(
                {
                  itemId: model.id,
                  target: FileUploadTargets.Certification,
                  isDeleting: isDeletingDocument,
                  qualificationId: model?.qualificationId
                },
                [...frontFile, ...backFile]
              );
            } else {
              return of(true);
            }
          })
        })
      ),
      map(certificationUpdateSuccess => new UpdateCertificationSuccess(certificationUpdateSuccess)),
      catchError(error => of(new UpdateCertificationFileError(error)))
    );
  });

  deleteCertification$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<CertificationsActions>(ECertificationsActions.DeleteCertification),
      map((action: DeleteCertification) => action.input),
      exhaustMap(input =>
        forkJoin({
          certification: this._api.deleteCertification(input.id),
          qualification: defer(() => {
            if (input.qualificationId != null) {
              return this._api.deleteQualification(input.qualificationId);
            } else {
              return of(true);
            }
          })
        }).pipe(
          map(payload => new DeleteCertificationSuccess(payload)),
          catchError(err => of(new DeleteCertificationError(err)))
        )
      )
    );
  });
}
