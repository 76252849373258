import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { AppUrls } from 'src/app/app-urls';
import { IAccountStateModel } from 'src/app/common/models/account-state-model';
import { RegistrationConstants } from 'src/app/common/models/registration-constants';
import { AccountActions } from './account.actions';
import { AccountState, initialAccountState } from './account.state';

const feature = createFeature({
  name: 'account',
  reducer: createReducer(
    initialAccountState,
    on(AccountActions.getState, (state: AccountState): AccountState => ({ ...state, loading: true })),
    on(AccountActions.getStateSuccess, (state: AccountState, { payload }): AccountState => ({ ...state, data: { ...state.data, ...payload }, loading: false })),
    on(AccountActions.getStateError, (state: AccountState, { error }): AccountState => ({ ...state, error, loading: false })),
    on(AccountActions.postRegistrationCheck, (state: AccountState): AccountState => ({ ...state, data: null, loading: true })),
    on(
      AccountActions.postRegistrationCheckSuccess,
      (state: AccountState, { payload }): AccountState => ({ ...state, data: { ...state.data, registrationCheckValue: payload }, loading: false })
    ),
    on(AccountActions.postRegistrationCheckError, (state: AccountState, { error }): AccountState => ({ ...state, error, loading: false }))
  )
});

// eslint-disable-next-line @ngrx/prefix-selectors-with-select
export const accountFeature = {
  ...feature,
  ...AccountActions,
  selectCanSeeInternational: createSelector(feature.selectData, (account: IAccountStateModel) => account?.canSeeInternational),
  selectCanSeeTravel: createSelector(feature.selectData, (account: IAccountStateModel) => account?.canSeeTravel),
  selectRegistrationCheckNavigation: createSelector(feature.selectData, (account: IAccountStateModel) => {
    if (account?.merlinId && account?.registrationCheckValue) {
      const { canSeeInternational, canSeeTravel, isNew, registrationCheckValue } = account;

      if ((!canSeeInternational && !canSeeTravel) || (canSeeInternational && !canSeeTravel && isNew)) {
        return AppUrls.LOGIN_ERROR;
      }

      switch (registrationCheckValue) {
        case RegistrationConstants.alreadyExists:
          return AppUrls.DASHBOARD;
        case RegistrationConstants.MultipleMatchesFound:
          return AppUrls.MULTIPLE_ACCOUNTS_FOUND;
        default:
          return true;
      }
    }

    return null;
  })
};
