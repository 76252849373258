import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { HeadingSize } from 'hc-design-system-lib';
import { IAvatarCardDetails } from 'hc-design-system-lib/lib/components/avatar-card/avatar-card.interfaces';
import { ITileCardConfig } from 'hc-design-system-lib/lib/components/cards/cards.interfaces';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { selectRecruiterAvatarConfiguration } from 'src/app/store/recruiter/recruiter.selectors';

export interface AvatarSupportCardDetails extends IAvatarCardDetails {
  jobTitle?: string;
  imageSource?: SafeHtml;
  text?: string;
  acuityURL?: string;
}

@Component({
  selector: 'app-support-team-tile',
  templateUrl: './support-team-tile.component.html',
  styleUrls: ['./support-team-tile.component.scss']
})
export class SupportTeamTileComponent implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  @ViewChild('supportAvatarsTemplate')
  supportAvatarsTemplate: TemplateRef<any>;

  cardConfig: ITileCardConfig = {
    title: 'My Support Team'
  };
  recruiterAvatarConfiguration$: Observable<AvatarSupportCardDetails[]> = this._store
    .select(selectRecruiterAvatarConfiguration(this.sanitizer))
    .pipe(filter(recruiter => recruiter != null && recruiter != undefined));
  headingSizeH6: HeadingSize = HeadingSize.H6;

  constructor(
    private _store: Store,
    private sanitizer: DomSanitizer
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
