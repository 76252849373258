import { AfterViewChecked, Component, OnDestroy, OnInit } from '@angular/core';
import { AppState, NursePortalApi, NavHelper, FormHeaderService } from 'src/app/services';
import { Observable, Subscription } from 'rxjs';
import { last } from 'rxjs/operators';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/app/app.state';
import { selectUserData } from 'src/app/store/userContext/userContext.selectors';
import { selectBusinessUnitLookup } from 'src/app/store/lookups/lookups.selectors';
import { IUserModel } from 'src/app/common';
import { PhoneNumberClicked } from 'src/app/store/segment/segment.actions';
import { UserContextActions } from 'src/app/store/userContext/userContext.actions';

interface IContactSearchOptions {
  lastName?: string;
  firstName?: string;
  email?: string;
  businessUnitId?: string;
}
@Component({
  selector: 'app-contact-select',
  templateUrl: './contact-select.component.html',
  styleUrls: ['./contact-select.component.scss']
})
export class ContactSelectComponent implements OnInit, OnDestroy, AfterViewChecked {
  contacts: any[];
  form: UntypedFormGroup;
  isSaving = false;
  businessUnits: any[];
  businessUnitLookup: Map<any, any>;
  hasNurseLink: boolean;

  user$: Observable<IUserModel> = this._store.select(selectUserData);

  businessUnitLookup$: Observable<Map<any, any>> = this._store.select(selectBusinessUnitLookup);

  userSubscription: Subscription;
  selectSubscription: Subscription;
  businessUnitLookupSubscription: Subscription;

  constructor(
    private _api: NursePortalApi,
    private _appState: AppState,
    private _fb: UntypedFormBuilder,
    private _navHelper: NavHelper,
    private _formHeaderService: FormHeaderService,
    private _store: Store<IAppState>
  ) {}

  ngOnInit(): void {
    this.addUserSubscription();
    this._createForm();
    this._formHeaderService.setTitle('Select Contact');
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
    this.selectSubscription?.unsubscribe();
    this.businessUnitLookupSubscription?.unsubscribe();
  }

  ngAfterViewChecked() {
    this._appState.setSpinnerState(false);
  }

  addBusinessUnitLookupSubscription() {
    this.businessUnitLookupSubscription = this.businessUnitLookup$.subscribe((businessUnitLookup: Map<any, any>) => {
      if (businessUnitLookup?.size > 0) {
        this.businessUnitLookup = businessUnitLookup;
        this.businessUnits = Array.from(businessUnitLookup.values());
      }
    });
  }

  addUserSubscription(): void {
    this.userSubscription = this.user$.subscribe((user: IUserModel) => {
      if (user) {
        this.hasNurseLink = user.hasNurseLink;
        this._formHeaderService.resetFormHeaderAttributes({
          form: this.form,
          backAction: () => this.goBack()
        });
      }
    });
  }

  private _createForm() {
    this.form = this._fb.group({
      lastName: new UntypedFormControl(),
      firstName: new UntypedFormControl(),
      email: new UntypedFormControl(),
      businessUnit: new UntypedFormControl()
    });
  }
  search() {
    const values = this.form.value;
    const options: IContactSearchOptions = {
      lastName: values.lastName,
      firstName: values.firstName,
      email: values.email,
      businessUnitId: (values.businessUnit && values.businessUnit.id) || null
    };
    this._api.contactSearch(options).subscribe(o => {
      this.contacts = o.contacts;
    });
  }
  select(contact: any) {
    if (this.isSaving === false) {
      this.isSaving = true;
      const body = {
        merlinId: contact.id,
        email: contact.email
      };
      const select = this._api.contactSelect(body);

      this._store.dispatch(UserContextActions.getUser());
      this._store.dispatch(UserContextActions.getNurse());

      this.selectSubscription = select.pipe(last()).subscribe(() => {
        this.isSaving = false;
        this._navHelper.goToDashBoard();
      });
    }
  }
  getBusinessUnitName(contact: any) {
    const businessUnit = this.businessUnitLookup.get(contact.businessUnitId);
    if (businessUnit != null) {
      return businessUnit.name;
    }

    return '';
  }
  clear() {
    this.form.reset();
  }

  goBack(): void {
    if (this.hasNurseLink) {
      return this._navHelper.goToDashBoard();
    }
    return this._navHelper.goToInternalLogin();
  }

  trackPhoneClick(contact: any): void {
    this._store.dispatch(new PhoneNumberClicked({ location: 'Contact Select', phoneType: 'Contact', phoneNumber: contact.mobilePhone }));
  }
}
