import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiClient } from './api-client.service';
import { AppInsights } from './app-insights.service';
import { AdditionalInfoPopupComponent, FlowHandlerComponent, FormHeaderComponent, RemoveSubmittalPopupComponent } from '../components';
import { MaterialModule } from '../material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HcDesignSystemLibModule } from 'hc-design-system-lib';
import { InterviewPopupComponent } from '../components/shared/interview-popup/interview-popup.component';
import { StatusInfoPopupComponent } from '../components/shared/status-info-popup/status-info-popup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingScreenSpinnerComponent } from '../components/shared/loading-screen-spinner/loading-screen-spinner.component';
import { AutoCompleteComponent } from '../components/shared/auto-complete/auto-complete.component';
import { EmptyListComponent } from '../components/shared/empty-list/empty-list.component';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateModule } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS, DateAdapter, MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { DATE_FORMATS } from '../common';
import { DateFieldAdapter } from '../common/date-adapter/date-field-adapter';
import { RecommendedJobsPopupComponent } from '../components/jobs/recommend-jobs/popup/recommended-jobs-popup.component';
import { FilPopUpComponent } from '../components/shared/fil-pop-up/fil-pop-up.component';
import { RecommendedJobsCarouselComponent } from '../components/jobs/recommend-jobs/carousel/recommended-jobs-carousel.component';
import { RecommendedJobsTileComponent } from '../components/jobs/recommend-jobs/tile/recommended-jobs-tile.component';
import { NewSubmittalsCarouselComponent } from '../modules/dashboard/components/new-submittals-carousel/new-submittals-carousel.component';
import { SegmentTrackCTADirective } from './segment/track-cta-click.directive';
import { BaseFormComponent } from '../components/shared/base-form/base-form.component';
import { CurrentSubmissionCarouselComponent } from '../modules/dashboard/components/current-submission-carousel/current-submission-carousel.component';
import { QuickLinksTileComponent } from '../modules/dashboard/components/quick-links-tile/quick-links-tile.component';
import { JobPreferencesTileComponent } from '../modules/preferences/job-preferences/pages/job-preferences-tile/job-preferences-tile.component';
import { SupportTeamTileComponent } from '../components/support-team-tile/support-team-tile.component';
import { VisaDetailsTileComponent } from '../modules/dashboard/components/visa-details-tile/visa-details-tile.component';
import { ImmigrationTrackerComponent } from '../modules/dashboard/components/immigration-tracker/immigration-tracker.component';
import { ProgressTrackerComponent } from '../modules/dashboard/components/progress-tracker/progress-tracker.component';
import { HelpModalComponent } from '../components/shared/help-modal-component/help-modal-component';
import { ArrivalsTileComponent } from '../modules/dashboard/components/arrivals-tile/arrivals-tile.component';
import { pipes } from '../custom-pipes';
import { SegmentTrackLinkClickDirective } from './segment/track-link-click.directive';

const SHARED_COMPONENTS = [
  ArrivalsTileComponent,
  InterviewPopupComponent,
  RemoveSubmittalPopupComponent,
  StatusInfoPopupComponent,
  AdditionalInfoPopupComponent,
  LoadingScreenSpinnerComponent,
  AutoCompleteComponent,
  EmptyListComponent,
  RecommendedJobsPopupComponent,
  RecommendedJobsCarouselComponent,
  RecommendedJobsTileComponent,
  NewSubmittalsCarouselComponent,
  FilPopUpComponent,
  FlowHandlerComponent,
  BaseFormComponent,
  CurrentSubmissionCarouselComponent,
  JobPreferencesTileComponent,
  QuickLinksTileComponent,
  SupportTeamTileComponent,
  VisaDetailsTileComponent,
  ImmigrationTrackerComponent,
  ProgressTrackerComponent,
  HelpModalComponent
];

@NgModule({
  declarations: [FormHeaderComponent, SegmentTrackCTADirective, SegmentTrackLinkClickDirective, ...SHARED_COMPONENTS, ...pipes],
  imports: [CommonModule, MaterialModule, FlexLayoutModule, CommonModule, HcDesignSystemLibModule, ReactiveFormsModule, FormsModule, MomentDateModule],
  exports: [
    ...SHARED_COMPONENTS,
    ...pipes,
    MaterialModule,
    FormHeaderComponent,
    FlexLayoutModule,
    CommonModule,
    HcDesignSystemLibModule,
    ReactiveFormsModule,
    FormsModule,
    MomentDateModule,
    SegmentTrackCTADirective,
    SegmentTrackLinkClickDirective
  ],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    { provide: DateAdapter, useClass: DateFieldAdapter },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: false } }
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [ApiClient, AppInsights]
    };
  }
}
