import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ITileCardConfig } from 'hc-design-system-lib/lib/components/cards/cards.interfaces';
import { Observable, Subject, takeUntil } from 'rxjs';
import { Arrivals } from 'src/app/common';
import { NavHelper } from 'src/app/services';
import { selectArrivals } from 'src/app/store/arrivals/arrivals.selectors';
import { airports } from '@nwpr/airport-codes';

@Component({
  selector: 'app-arrivals-tile',
  templateUrl: './arrivals-tile.component.html',
  styleUrls: ['./arrivals-tile.component.scss']
})
export class ArrivalsTileComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  tileConfig: ITileCardConfig = {
    title: 'Arrival Information'
  };

  arrivals$: Observable<Arrivals> = this._store.select(selectArrivals);

  flightDetails: string = 'TBD';

  constructor(
    private readonly _store: Store,
    private readonly _navHelper: NavHelper
  ) {}

  ngOnInit() {
    this.arrivals$.pipe(takeUntil(this.destroy$)).subscribe(arrivals => {
      if (arrivals?.travelExpenses?.flightDetails) {
        let airportName = airports.find(airport => airport.iata === arrivals.travelExpenses.flightDetails[0].airportCode)?.name;

        this.flightDetails = airportName ? airportName + ' (' + arrivals.travelExpenses.flightDetails[0].airportCode.toUpperCase() + ')' : 'TBD';
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  nagivateToArrivalDetails() {
    this._navHelper.goToArrivals();
  }
}
