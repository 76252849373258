import { createActionGroup, emptyProps } from '@ngrx/store';
import { IAccountStateModel } from 'src/app/common/models/account-state-model';

export const AccountActions = createActionGroup({
  source: 'ACCOUNT',
  events: {
    'get state': emptyProps(),
    'get state success': (payload: IAccountStateModel) => ({ payload }),
    'get state error': (error: Error) => ({ error }),
    'post registration check': emptyProps(),
    'post registration check success': (payload: number) => ({ payload }),
    'post registration check error': (error: Error) => ({ error })
  }
});
