import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of, retry } from 'rxjs';
import { IAccountStateModel } from 'src/app/common/models/account-state-model';
import { UsersApiService } from 'src/app/services';
import { UserContextActions } from '../userContext/userContext.actions';
import { AccountActions } from './account.actions';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AccountEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly _usersApiService: UsersApiService
  ) {}

  getAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.getState, AccountActions.postRegistrationCheckSuccess, UserContextActions.submitRegistrationSuccess, UserContextActions.checkVerificationKeySuccess),
      exhaustMap(() =>
        this._usersApiService.getAccountState().pipe(
          retry(2),
          map((accountStateData: IAccountStateModel) => AccountActions.getStateSuccess(accountStateData)),
          catchError((error: Error) => of(AccountActions.getStateError(error)))
        )
      )
    );
  });

  postRegistrationCheck$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.postRegistrationCheck),
      exhaustMap(() =>
        this._usersApiService.registrationCheck().pipe(
          map(resp => AccountActions.postRegistrationCheckSuccess(resp)),
          catchError((error: unknown) => of(AccountActions.postRegistrationCheckError(error as HttpErrorResponse)))
        )
      )
    );
  });
}
