import { initializeData, IDataState } from 'src/app/store/app/app.models';
import { Arrivals, ArrivalsActivity } from '../../common/models/arrivals';

export interface IArrivalsState {
  arrivals: IDataState<Arrivals>;
  arrivalsActivities: IDataState<ArrivalsActivity[]>;
  updateArrivalsActityResult: IDataState<any>;
}

export const initialArrivalsState: IArrivalsState = {
  arrivals: initializeData(),
  arrivalsActivities: initializeData(),
  updateArrivalsActityResult: initializeData()
};
