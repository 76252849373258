import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { NavHelper, FormHeaderService } from 'src/app/services';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDocumentPopUpComponent } from '../../shared/delete-document-pop-up/delete-document-pop-up.component';
import { CandidateQualification } from 'src/app/common/models/candidate-qualification';
import { Store } from '@ngrx/store';
import { AngularticsEventTrack } from 'src/app/store/angulartics2/angulartics2.actions';
import { combineLatest, Observable, Subject } from 'rxjs';
import { selectDocuments, selectDocumentsLoading } from 'src/app/store/userContext/userContext.selectors';
import { skipWhile, takeUntil } from 'rxjs/operators';
import { categoryNames } from 'src/app/common/models/documents';
import { UserContextActions } from 'src/app/store/userContext/userContext.actions';

enum statusReason {
  Completed = 948050002,
  Pending = 948050003,
  Expired = 948050015
}

enum docStatus {
  Active = 0,
  Inactive = 1
}

enum filterIds {
  active = 0,
  expired = 1,
  all = 2
}

class GroupedQuals {
  displayName: string;
  displayId: number;
  docs: CandidateQualification[] = [];
  icon: string;

  constructor(name: string, id: number, icon: string) {
    this.displayName = name;
    this.displayId = id;
    this.docs = [];
    this.icon = icon;
  }

  getDisplayNumber(): number {
    return this.docs.filter(o => o.display !== false).length;
  }
}

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'full-height'
  }
})
export class DocumentsComponent implements OnInit, OnDestroy {
  @ViewChild('customTemplate')
  set customTemplate(ref: TemplateRef<any>) {
    setTimeout(() => {
      this.customeTemplateRef = ref;
      this._formHeaderService.setCustomContent(this.customeTemplateRef);
    }, 0);
  }

  private customeTemplateRef: TemplateRef<any>;
  private readonly destroy$ = new Subject<void>();
  displayedQualifiations: CandidateQualification[] = [];
  sortByCategory = true;
  sortAscending = true;
  editMode = false;
  imageBlobUrl: any;
  currentFilterId = filterIds.active;
  get filterIds() {
    return filterIds;
  }

  categories: GroupedQuals[] = [
    new GroupedQuals('Background and Verifications', categoryNames.BackgroundAndVerifications, 'verified_user'),
    new GroupedQuals('Employment', categoryNames.Employment, 'work'),
    new GroupedQuals('Facility Specific Requirements', categoryNames.FacilitySpecificRequirements, 'business'),
    new GroupedQuals('License and Certifications', categoryNames.LicenseAndCertifications, 'book'),
    new GroupedQuals('Medical and Occupational Health', categoryNames.MedicalAndOccupationalHealth, 'local_hospital'),
    new GroupedQuals('Skills Testing and Training', categoryNames.SkillsTestingAndTraining, 'insert_chart'),
    new GroupedQuals('Other', categoryNames.Other, 'description')
  ];

  categoryLookup = new Map(this.categories.map(i => [i.displayId, i]));
  document$: Observable<CandidateQualification[]> = this._store.select(selectDocuments);

  documentLoading$: Observable<boolean> = this._store.select(selectDocumentsLoading);

  constructor(
    private _location: Location,
    private _nav: NavHelper,
    private _dialog: MatDialog,
    private _formHeaderService: FormHeaderService,
    private _store: Store
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    combineLatest([this.document$, this.documentLoading$])
      .pipe(
        skipWhile(([, documentLoading]) => documentLoading),
        takeUntil(this.destroy$)
      )
      .subscribe(([document]: [CandidateQualification[], boolean]) => {
        if (document != null) {
          this.displayedQualifiations = document.map(item => ({ ...item, selected: false }));
        }
        this.filterListByStatus(filterIds.active);
        this.sort();
        this.sortQualifcationsIntoCategories();
        this._formHeaderService.resetFormHeaderAttributes({
          title: 'Document Library',
          showBackButton: false
        });
      });
  }

  sortQualifcationsIntoCategories(): void {
    this.categoryLookup.forEach(o => (o.docs = []));
    this.displayedQualifiations.forEach(o => {
      const rightCategory = this.categoryLookup.get(o.category);
      if (rightCategory) {
        rightCategory.docs.push(o);
      }
    });
  }

  sortCategory() {
    this._store.dispatch(new AngularticsEventTrack('Click', 'Documents', 'List View'));
    this.sortByCategory = true;
  }

  sortAlpha() {
    this._store.dispatch(new AngularticsEventTrack('Click', 'Documents', 'Alphabetical View'));
    this.sortByCategory = false;
    this.sort();
  }

  sortToggle() {
    this.sortByCategory = !this.sortByCategory;
  }

  sort() {
    if (this.sortAscending) {
      this.displayedQualifiations.sort(function (a, b) {
        return a.title < b.title ? -1 : 1;
      });
    } else {
      this.displayedQualifiations.sort(function (a, b) {
        return a.title > b.title ? -1 : 1;
      });
    }

    this.sortAscending = !this.sortAscending;
  }

  getDocument(item: CandidateQualification) {
    if (!this.editMode) {
      this._store.dispatch(new AngularticsEventTrack('Click', 'Documents', 'View Document'));
      this._store.dispatch(UserContextActions.getDocumentsById(item));
    }
  }

  goBack() {
    this._location.back();
  }

  filterListByStatus(id: number) {
    this.currentFilterId = id;
    this.displayedQualifiations.forEach(o => (o.display = true));
    switch (id) {
      case filterIds.active:
        this.displayedQualifiations
          .filter(x => x.expired === true || !((x.statusReason === statusReason.Completed || x.statusReason === statusReason.Pending) && x.docStatus === docStatus.Active))
          .forEach(o => (o.display = false));
        break;
      case filterIds.expired:
        this.displayedQualifiations.filter(x => x.expired !== true).forEach(o => (o.display = false));
        break;
      case filterIds.all:
        break;
    }
  }

  edit(editStatus: boolean) {
    this.editMode = editStatus;
  }

  deleteDocument(item: CandidateQualification) {
    const dialogRef = this._dialog.open(DeleteDocumentPopUpComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        key: item
      }
    });
    this._store.dispatch(new AngularticsEventTrack('Delete', 'Documents', 'Remove Document'));
    dialogRef.afterClosed().subscribe(
      x => {
        if (x) {
          this._store.dispatch(UserContextActions.deleteDocuments(item));
        }
      },
      error => console.error(error)
    );
  }

  goToBulkUpload = () => {
    this._nav.goToBulkUpload();
  };

  countOfDisplayableQuals(): number {
    return this.getDisplayedQualifications().length;
  }

  getDisplayedQualifications(): CandidateQualification[] {
    return this.displayedQualifiations.filter(o => o.display !== false);
  }
}
