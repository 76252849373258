import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AccountActions } from '../store/account/account.actions';
import { accountFeature } from '../store/account/account.reducers';
import { IAppState } from '../store/app/app.state';

@Injectable({
  providedIn: 'root'
})
export class RegistrationCheckGuard implements CanActivate {
  registrationCheckNavigation$ = this._store.select(accountFeature.selectRegistrationCheckNavigation);

  constructor(
    private _router: Router,
    private _store: Store<IAppState>
  ) {}

  canActivate(_: ActivatedRouteSnapshot, __: RouterStateSnapshot): Observable<true | UrlTree> {
    this._store.dispatch(AccountActions.postRegistrationCheck());

    return this.registrationCheckNavigation$.pipe(
      filter(navigationLink => !!navigationLink),
      map((navigationLink: string | true) => (typeof navigationLink === 'string' ? this._router.createUrlTree([navigationLink]) : navigationLink))
    );
  }
}
