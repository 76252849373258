import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavHelper } from 'src/app/services';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NurseModel } from 'src/app/common';
import { selectNurseData, selectVerificationCheckResultLoading } from 'src/app/store/userContext/userContext.selectors';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { VerificationType } from 'src/app/common/constants';
import { NotificationService } from 'hc-design-system-lib';
import { UserContextActions } from 'src/app/store/userContext/userContext.actions';

@Component({
  selector: 'app-verify-sms',
  templateUrl: './verify-sms.component.html',
  styleUrls: ['./verify-sms.component.scss']
})
export class VerifySMSComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  userEmail: string;
  userPhoneNumber: string;
  form: UntypedFormGroup;

  nurse$: Observable<NurseModel> = this.store.select(selectNurseData);

  verificationCheckLoading$: Observable<boolean> = this.store.select(selectVerificationCheckResultLoading);

  constructor(
    private _navHelper: NavHelper,
    public _notificationService: NotificationService,
    private _fb: UntypedFormBuilder,
    private store: Store
  ) {}

  ngOnInit() {
    this._createForm();
    this.addNurseSubscription();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  _createForm() {
    this.form = this._fb.group({
      key: new UntypedFormControl('', Validators.required)
    });
  }

  addNurseSubscription(): void {
    this.nurse$
      .pipe(
        filter(nurse => nurse !== null),
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe((nurseData: NurseModel) => {
        if (nurseData) {
          this.userEmail = nurseData.email;
          this.userPhoneNumber = nurseData.mobileNumber;

          if (!nurseData.isVerified && nurseData.verificationType !== 2) {
            this._navHelper.goToVerifyUserEmail();
          } else if (!nurseData.isVerified) {
            this.store.dispatch(UserContextActions.sendVerificationSms());
          } else {
            this._navHelper.goToDashBoard();
          }
        }
      });
  }

  verifySMS() {
    // Handles the verification through SMS
    this.store.dispatch(UserContextActions.checkVerificationKey({ smsKey: this.form.controls.key.value }));
  }

  navigateToVerifySupport() {
    this._navHelper.goToVerifySupport();
  }

  navigateToEmailVerification() {
    this.store.dispatch(UserContextActions.setVerificationType(VerificationType.Email));
  }

  resendSMS() {
    this.store.dispatch(UserContextActions.sendVerificationSms(true));
  }

  formValid(): boolean {
    return this.form.valid;
  }
}
