import { ActionReducerMap } from '@ngrx/store';
import { IAppState } from 'src/app/store/app/app.state';
import { recruiterReducer } from 'src/app/store/recruiter/recruiter.reducers';
import { dashboardReducer } from 'src/app/store/dashboard/dashboard.reducers';
import { jobsReducer } from 'src/app/store/jobs/jobs.reducers';
import { lookupsReducer } from 'src/app/store/lookups/lookups.reducers';
import { locationReducer } from 'src/app/store/location/location.reducers';
import { tasksReducer } from 'src/app/store/tasks/tasks.reducers';
import { workHistoryReducer } from 'src/app/store/workHistory/workHistoryContext.reducers';
import { uiReducer } from 'src/app/store/ui/ui.reducers';
import { educationReducer } from '../education/education.reducers';
import { flagsReducer } from '../flags/flags.reducers';
import { certificationsReducer } from '../certifications/certifications.reducers';
import { licensesReducer } from '../licenses/licenses.reducers';
import { sharedDataReducer } from '../sharedData/sharedData.reducers';
import { technologyReducer } from '../technologies/technology.reducer';
import { skillsChecklistReducer } from '../skillsChecklist/skillsChecklist.reducers';
import { referencesReducer } from '../references/references.reducers';
import { facilitiesReducer } from '../facilities/facilities.reducers';
import { arrivalsReducer } from '../arrivals/arrivals.reducers';
import { userContextFeature } from '../userContext/userContext.reducers';
import { accountFeature } from '../account/account.reducers';

export const appReducers: ActionReducerMap<IAppState, any> = {
  account: accountFeature.reducer,
  userContext: userContextFeature.reducer,
  jobs: jobsReducer,
  dashboard: dashboardReducer,
  recruiter: recruiterReducer,
  lookups: lookupsReducer,
  location: locationReducer,
  tasks: tasksReducer,
  workHistoryContext: workHistoryReducer,
  ui: uiReducer,
  education: educationReducer,
  flags: flagsReducer,
  certifications: certificationsReducer,
  licenses: licensesReducer,
  sharedData: sharedDataReducer,
  skillsChecklist: skillsChecklistReducer,
  technology: technologyReducer,
  references: referencesReducer,
  facilities: facilitiesReducer,
  arrivals: arrivalsReducer
};
