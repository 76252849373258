import { BasicSnackBarService } from 'src/app/services/basic-snack-bar.service';
import { DeleteConfirmationService } from 'src/app/services/delete-confirmation.service';
import { DeleteConfirmationContext } from '../../shared/delete-confirmation/delete-confirmation.component';
import { NavigationProfile, NavigationItem } from 'hc-design-system-lib/lib/components/navigation/navigation.interfaces';
import { environment } from 'src/environments/environment';
import { HcEvent } from 'hc-design-system-lib/lib/models/hc-event';
import { SearchFilterService } from '../../../services/search-filter-service';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { selectNurseData, selectShowSpecialityPopup, selectUserData } from 'src/app/store/userContext/userContext.selectors';
import { IAppState } from 'src/app/store/app/app.state';
import { filter, take } from 'rxjs/operators';
import { AppUrls } from 'src/app/app-urls';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { IBackable } from 'src/app/common/contracts/ibackable';
import { FormHeaderComponent } from 'src/app/shared/components/form-header/form-header.component';
import { TaskNavigatorService } from 'src/app/services/task-navigator.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, Component, EventEmitter, HostBinding, HostListener, NgZone, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NurseModel, IRouteOptions, IUserModel, ILookup } from 'src/app/common';
import { NavHelper, NursePortalApi, AppState, FormHeaderService } from 'src/app/services';
import { Event as RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, Router, ActivatedRoute } from '@angular/router';
import { selectSpecialtyLookup, selectProfessionLookup } from 'src/app/store/lookups/lookups.selectors';
import { SetJobFilterV2 } from 'src/app/store/jobs/jobs.actions';
import { mainComponentLoaded, setScreenWidth, setTabbarActiveItemIndex } from 'src/app/store/ui/ui.actions';
import { selectIsMobile, selectIsTablet, selectMenuItems, selectNavigationProfile, selectTabbarData } from 'src/app/store/ui/ui.selectors';
import { NavigationItemClicked, SignedOut } from 'src/app/store/segment/segment.actions';
import { CardElevation, DialogService, IDialogParameters } from 'hc-design-system-lib';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { SpecialtyPopupComponent } from '../specialty-popup/specialty-popup.component';
import { ITabBarState } from 'src/app/store/ui/ui.state';
import { signOutItem } from './main.constants';
import { UserContextActions } from 'src/app/store/userContext/userContext.actions';
import { accountFeature } from 'src/app/store/account/account.reducers';
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy, AfterViewInit {
  @HostBinding('class.router-injected') routerInjected = true;

  private specialtyPopupTemplate: TemplateRef<SpecialtyPopupComponent>;
  @ViewChild('specialtyPopup')
  set setSpecialtyPopupTemplate(content: TemplateRef<SpecialtyPopupComponent>) {
    if (content && !this.specialtyPopupTemplate) {
      this.specialtyPopupTemplate = content;
    }
  }

  supportButton = {
    iconName: 'help',
    title: 'Support'
  };
  supportButtonClickEvent: HcEvent = {
    event: null,
    eventName: 'supportButtonClick',
    eventValue: { route: `/${AppUrls.SUPPORT}`, displayName: 'Support' }
  };
  loading = true;
  showBackButton = true;
  showFormHeader = false;
  hideNavbar = false;
  hideTabBar = false;
  hideProfile = false;
  cardStyles = '';
  disableNavContentPadding = false;
  backUrl: string = null;
  isSaving = false;
  isOnRegistrationPath = false;
  isOnEnrollPath = false;
  navigationProfile: NavigationProfile = null;
  signOutItem = signOutItem;

  businessUnit: any;
  mobileQuery: MediaQueryList;
  title: any;
  header: FormHeaderComponent;
  currentComponent: any;
  basicSnackBarEmitter: EventEmitter<any>;
  deleteConfirmationEvent: EventEmitter<DeleteConfirmationContext>;
  isOnAssignment: boolean;
  screenWidth: number;
  nurseSubscription: Subscription;
  professionLookup: Map<string, ILookup<string>>;
  specialtyLookup: Map<string, ILookup<string>>;

  professionLookupSubscription: Subscription;
  professionLookup$: Observable<Map<string, ILookup<string>>> = this._store.select(selectProfessionLookup);

  specialtyLookupSubscription: Subscription;
  specialtyLookup$: Observable<Map<string, ILookup<string>>> = this._store.select(selectSpecialtyLookup);

  userData$: Observable<IUserModel> = this._store.select(selectUserData);
  nurse$: Observable<NurseModel> = this._store.select(selectNurseData);
  tabBar$: Observable<ITabBarState>;
  menuItems$: Observable<NavigationItem[]> = this._store.select(selectMenuItems);
  navigationProfile$: Observable<NavigationProfile> = this._store.select(selectNavigationProfile);

  showInternationalContent$: Observable<boolean> = this._store.select(accountFeature.selectCanSeeInternational);
  showTravelContent$: Observable<boolean> = this._store.select(accountFeature.selectCanSeeTravel);

  showSpecialtyPopup$: Observable<boolean> = this._store.select(selectShowSpecialityPopup);
  showSpecialtyPopupSubscription: Subscription;

  isMobile$: Observable<boolean> = this._store.select(selectIsMobile);

  isTablet$: Observable<boolean> = this._store.select(selectIsTablet);

  @HostListener('window:resize', ['$event'])
  onResize() {
    this._store.dispatch(setScreenWidth({ screenWidth: window.innerWidth }));
  }

  @ViewChild('headerRef')
  set headerComponent(formHeader: FormHeaderComponent) {
    this.header = formHeader;
    this.setComponentBack();
  }

  constructor(
    media: MediaMatcher,
    private _api: NursePortalApi,
    private _router: Router,
    private _navService: NavHelper,
    private _route: ActivatedRoute,
    private _appState: AppState,
    private _formService: FormHeaderService,
    private _sanitizer: DomSanitizer,
    private _taskNav: TaskNavigatorService,
    private _store: Store<IAppState>,
    private _dialogService: DialogService,
    private ngZone: NgZone,
    _basicSnackBar: BasicSnackBarService,
    _deleteConfirmation: DeleteConfirmationService
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');

    this._router.events.subscribe((e: RouterEvent) => {
      this.navigationInterceptor(e);
    });
    this.basicSnackBarEmitter = _basicSnackBar.displayEvent;
    this.deleteConfirmationEvent = _deleteConfirmation.onConfirm;
    this.tabBar$ = this._store.select(selectTabbarData);
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loading = true;
    }
    if (event instanceof NavigationEnd) {
      this.loading = false;
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.loading = false;
    }
    if (event instanceof NavigationError) {
      this.loading = false;
    }
  }

  ngOnInit() {
    this._store.dispatch(mainComponentLoaded());
    this.addProfessionLookupSubscription();
    this.addSpecialtyLookupSubscription();
    this.addShowSpecialtyPopupSubscription();
    this._appState.setSpinnerState(false);
    this.sendSignIn();
    this.setNavContentStyles();
    this._navService.viewMatch = this.mobileQuery.matches;
    this._toggleToolbar();
    this.setTitle();
    this._router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(() => {
      this.isOnRegistrationPath = this._route.snapshot.queryParamMap.get('registrationPath') === 'true';
      this._formService.resetFormHeaderAttributes({});
      this._dialogService.closeAll();
      this._toggleToolbar();
      this.setTitle();
      this.isSaving = false;
      this.setNavContentStyles();
      document.getElementsByClassName('hc-sidenav-content-page')[0]?.scrollTo(0, 0);
      this.updateTabbarSelectedIndex();
    });
    this._router.events.pipe(filter(e => e instanceof NavigationStart)).subscribe(() => {
      this.isSaving = true;
      this._formService.setDisableActions(true);
    });
    this.checkSearchFilters();
    this.initAppRouting();
    this.updateTabbarSelectedIndex();
  }

  ngOnDestroy() {
    this.professionLookupSubscription?.unsubscribe();
    this.specialtyLookupSubscription?.unsubscribe();
    this.showSpecialtyPopupSubscription?.unsubscribe();
  }

  private initAppRouting() {
    if (Capacitor.isNativePlatform()) {
      App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        this.ngZone.run(() => {
          // Example url: https://ondemand-test.hctravelnursing.com/tabs/tab2
          // slug = /tabs/tab2
          const slug = event.url.split('.com').pop();
          if (slug && slug !== event.url) {
            this._router.navigateByUrl(slug);
            this.updateTabbarSelectedIndex();
          }
          // If no match, do nothing - let regular routing
          // logic take over
        });
      });
    }
  }

  addProfessionLookupSubscription(): void {
    this.professionLookupSubscription = this.professionLookup$.subscribe(professionLookup => {
      this.professionLookup = professionLookup;
    });
  }

  addSpecialtyLookupSubscription(): void {
    this.specialtyLookupSubscription = this.specialtyLookup$.subscribe(specialtyLookup => {
      this.specialtyLookup = specialtyLookup;
    });
  }
  addShowSpecialtyPopupSubscription() {
    this.showSpecialtyPopupSubscription = this.showSpecialtyPopup$.pipe(filter(() => !!this.specialtyPopupTemplate)).subscribe(showSpecialtyPopup => {
      const isEnrolling = this._router.url === `/${AppUrls.ENROLL}`;
      const isOnRegistrationPath = this._route.snapshot.queryParamMap.get('registrationPath') === 'true';
      if (showSpecialtyPopup && !isOnRegistrationPath && !isEnrolling) {
        this.showSpecialtyPopup();
      }
    });
  }

  showSpecialtyPopup() {
    const dialogData: IDialogParameters = {
      title: null,
      text: '',
      showCloseIcon: false,
      elevation: CardElevation.Default,
      icon: null,
      noStyling: true,
      template: this.specialtyPopupTemplate,
      width: 'auto'
    };
    this._dialogService.showDialog(dialogData, true, true, false);
  }

  ngAfterViewInit(): void {
    this.setComponentBack();

    //@ts-ignore
    if (!window?.zESettings?.webWidget?.authentication?.chat?.jwtFn) {
      const api = this._api;

      //@ts-ignore
      var zenDeskLoaded = window?.zESettings;
      if (zenDeskLoaded != null) {
        //@ts-ignore
        zE('webWidget', 'updateSettings', {
          webWidget: {
            authenticate: {
              chat: {
                jwtFn: function (callback) {
                  api.getZendeskToken().then(jwt => {
                    // Todo: Move jwt logic to ngrx.
                    callback(jwt);
                  });
                }
              }
            }
          }
        });
      }
    }
  }

  checkSearchFilters(): void {
    const service = new SearchFilterService(this.professionLookup, this.specialtyLookup);

    if (service.isAdvanced) {
      const searchFilters = service.getSearchParams();
      this._navService.goToJobSearchFilters(searchFilters);
    }
  }

  _toggleToolbar() {
    let activatedRoute = this._route.firstChild;
    while (activatedRoute.firstChild) {
      activatedRoute = activatedRoute.firstChild;
    }

    const data = activatedRoute.routeConfig.data;
    if (data && data.options) {
      const options = <IRouteOptions>data.options;
      this.showFormHeader = !options.hideFormHeader;
      this.hideNavbar = options.hideNavBar;
      this.hideTabBar = options.hideTabBar;
      this.hideProfile = options.hideProfile;
      this.showBackButton = options.showBackButton;
      if (this._route.snapshot.queryParamMap.get('fromTask') === 'true') {
        this.backUrl = AppUrls.TASKS;
      } else if (this._route.snapshot.queryParamMap.get('jobId')) {
        this.backUrl = AppUrls.APPLICATION + '/' + this._route.snapshot.queryParamMap.get('jobId');
      } else {
        this.backUrl = options.backUrl;
      }
    } else {
      this.showFormHeader = true;
      this.hideNavbar = false;
      this.hideTabBar = false;
      this.showBackButton = true;
      this.backUrl = null;
      this.hideProfile = false;
    }
    if (activatedRoute.routeConfig.path === AppUrls.JOB_PREFERENCES && this.isOnRegistrationPath) {
      this.showBackButton = false;
    }
    this._formService.setShowBackButton(this.showBackButton);
  }

  setTitle(): void {
    // REMOVING THE '/' AND ANY PARAMETERS
    const x = this._router.url.slice(1).split(/[/?,/#]/)[0];

    // SPLITTING ON AN '-' AND CAPITALIZING FIRST LETTER OF EACH WORD
    let newTitle = x
      .split('-')
      .map(o => o.charAt(0).toUpperCase() + o.substring(1))
      .join(' ');
    if (newTitle === 'Profile Menu') {
      newTitle = 'Profile';
    }
    if (newTitle === 'License Landing Page') {
      newTitle = 'Add License';
    }
    if (newTitle === 'Time') {
      newTitle = 'Time Tracking';
    }
    if (newTitle === 'Verify') {
      newTitle = 'Contact Us';
    }
    if (newTitle === 'Nursys Setup') {
      newTitle = 'License Verification';
    }
    if (newTitle === 'Questionnaire') {
      newTitle = 'Background Questionnaire';
    }
    this._formService.setTitle(newTitle);
  }

  navigate(event: HcEvent, navigationPlacement: string, nurse: NurseModel, user: IUserModel): void {
    this._taskNav.removePayJobInfoTag();
    this._store.dispatch(new SetJobFilterV2(null));

    if (event.eventValue.route) {
      this.segmentNavigationTrack(event?.eventValue, navigationPlacement);
      this._formService.navigate(event.eventValue.route);
    } else {
      switch (event.eventValue.exec) {
        case 1:
          this.resetInternalContactSelect(nurse, user);
          this._store.dispatch(new SignedOut());
          this.logout();
          break;
        case 2:
          this.clearCache();
          break;
        case 3:
          this.reset();
          break;
      }
    }
  }

  segmentNavigationTrack(navigationValues, navigationPlacement): void {
    let navigationUrl = environment.appBaseUrl + navigationValues.route;
    this._store.dispatch(
      new NavigationItemClicked({
        navigationText: navigationValues.displayName,
        navigationPlacement,
        navigationUrl
      })
    );
  }

  isRoute(routeString: string): boolean {
    return this._router.url.indexOf(routeString) >= 0 && !this.isSaving;
  }

  goToDashboard(): void {
    this._navService.goToDashBoard();
  }

  imageToShow(nurseData: NurseModel): SafeUrl {
    if (nurseData?.profilePicture) {
      return this._sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + nurseData.profilePicture);
    }
    return null;
  }

  sendSignIn(): void {
    this._store.dispatch(UserContextActions.postSignIn());
  }

  onActivate(componentRef: any) {
    this.currentComponent = componentRef;
    this.setComponentBack();
  }

  private setComponentBack() {
    if (this.currentComponent && this.instanceOfA(this.currentComponent)) {
      const backable = this.currentComponent as IBackable;
      if (backable) {
        this._formService.backAction = () => backable.goBack();
      }
    } else {
      this.header = null;
    }
  }

  private instanceOfA(object: any): object is IBackable {
    return 'goBack' in object;
  }

  setNavContentStyles(): void {
    const hideCard = this._router.url.indexOf('submittals') > 0 || this._router.url.indexOf('on-assignment') > 0;
    const fullWidthHideCard = this.isFullWidthHideCard();

    let styles = 'margin:auto; min-height:100%; overflow: hidden; background-color:white;';
    if (fullWidthHideCard) {
      styles = 'margin: auto; min-width: 100%; min-height:100%; background-color: transparent; border: none; border-radius: 0; box-shadow: none;';
    } else if (hideCard) {
      styles = 'margin:auto; max-width: 1080px; min-height:100%; background-color: transparent; border: none; box-shadow: none;';
      if (this._router.url.indexOf('submittals') === 4) {
        styles += 'border-radius: 0;';
      }
    }

    this.cardStyles = styles;
  }

  shouldDisableNavContentPadding(isMobile: boolean): boolean {
    return (
      this._router.url.startsWith('/jobs') ||
      this._router.url.startsWith('/preferences') ||
      this._router.url.includes(AppUrls.JOB_SEARCH) ||
      (this._router.url.startsWith('dashboard') && isMobile)
    );
  }

  isFullWidthHideCard(): boolean {
    return (
      this._router.url.startsWith('/jobs/') ||
      this._router.url.startsWith('/dashboard') ||
      this._router.url.startsWith('/preferences') ||
      (this._router.url.indexOf('job') > 0 && !this._route.snapshot.queryParamMap.has('jobId'))
    );
  }

  async logout(): Promise<void> {
    this._store.dispatch(UserContextActions.clearCacheAndLogOut());
  }

  resetInternalContactSelect(nurse: NurseModel, user: IUserModel): void {
    const { merlinId } = nurse;
    const { isInternal, hasContactSelect } = user;

    if (isInternal && hasContactSelect) {
      const body = {
        merlinId: merlinId
      };
      this._store.dispatch(UserContextActions.contactSelectReset(body));
    }
  }

  reset(): void {
    this._store.dispatch(UserContextActions.resetUser());
  }

  clearCache(): void {
    this._store.dispatch(UserContextActions.clearCache());
  }

  navigateToOnAssignment(): void {
    this._navService.goToOnAssignment();
  }

  setLogo(showInternational: boolean, isMobile: boolean, isTablet: boolean): string {
    if (showInternational) {
      return isMobile && !isTablet ? '/assets/img/on-demand/ondemand-international-mobile-logo.svg' : 'assets/img/on-demand/ondemand-international-large-screen-logo.svg';
    } else {
      return isMobile && !isTablet ? '/assets/img/on-demand/ondemand-mobile-logo.svg' : 'assets/img/on-demand/ondemand-large-screen-logo.svg';
    }
  }

  private updateTabbarSelectedIndex(): void {
    let currentUrl = this._router.url;
    currentUrl = currentUrl.replace(/^([^\/]*\/[^\/]*)\/?.*$/, '$1'); // Drop everything after the second /
    this.tabBar$.pipe(take(1)).subscribe(tabBar => {
      const tabIndex = tabBar.tabBarItems.findIndex(item => currentUrl.includes(item.route));
      if (tabIndex !== -1) {
        this._store.dispatch(setTabbarActiveItemIndex({ tabbarActiveItemIndex: tabIndex }));
      }
    });
  }
}
