import { NgModule } from '@angular/core';
import { UserContextEffects } from 'src/app/store/userContext/userContext.effects';
import { EffectsModule as Effects } from '@ngrx/effects';
import { JobsEffects } from 'src/app/store/jobs/jobs.effects';
import { DashboardEffects } from 'src/app/store/dashboard/dashboard.effects';
import { SegmentEffects } from 'src/app/store/segment/segment.effects';
import { RecruiterEffects } from 'src/app/store/recruiter/recruiter.effects';
import { LocationEffects } from 'src/app/store/location/location.effects';
import { Angulartics2Effects } from 'src/app/store/angulartics2/angulartics2.effects';
import { WorkHistoryContextEffects } from 'src/app/store/workHistory/workHistoryContext.effects';
import { TasksEffects } from 'src/app/store/tasks/tasks.effects';
import { LookupsEffects } from 'src/app/store/lookups/lookups.effects';
import { EducationEffects } from '../education/education.effects';
import { FlagsEffects } from '../flags/flags.effects';
import { CertificationsEffects } from '../certifications/certifications.effects';
import { LicensesEffects } from '../licenses/licenses.effects';
import { SharedDataEffects } from '../sharedData/sharedData.effects';
import { TechnologyEffects } from '../technologies/technology.effects';
import { SkillsChecklistEffects } from '../skillsChecklist/skillsChecklist.effects';
import { ReferencesEffects } from '../references/references.effects';
import { FacilityEffects } from '../facilities/facilities.effects';
import { ArrivalsEffects } from '../arrivals/arrivals.effects';
import { UiEffects } from '../ui/ui.effects';
import { AccountEffects } from '../account/account.effects';

@NgModule({
  imports: [
    Effects.forRoot([
      AccountEffects,
      UserContextEffects,
      JobsEffects,
      DashboardEffects,
      SegmentEffects,
      RecruiterEffects,
      LocationEffects,
      RecruiterEffects,
      Angulartics2Effects,
      TasksEffects,
      WorkHistoryContextEffects,
      LookupsEffects,
      EducationEffects,
      FlagsEffects,
      CertificationsEffects,
      LicensesEffects,
      SharedDataEffects,
      SkillsChecklistEffects,
      TechnologyEffects,
      ReferencesEffects,
      FacilityEffects,
      ArrivalsEffects,
      UiEffects
    ])
  ]
})
export class EffectsModule {}
