import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AppUrls } from '../app-urls';
import { Store } from '@ngrx/store';
import { Observable, skipWhile, map, combineLatest } from 'rxjs';
import { featureFlagNames } from '../services/feature-flags.service';
import { selectIsSpecificFlagOn } from '../store/flags/flags.selectors';
import { accountFeature } from '../store/account/account.reducers';

@Injectable({
  providedIn: 'root'
})
export class JobPreferencesRegistrationGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _store: Store
  ) {}

  contactPreferencesOn$: Observable<boolean> = this._store
    .select(selectIsSpecificFlagOn(featureFlagNames.communicationPreferences))
    .pipe(skipWhile(contactPreferencesOn => contactPreferencesOn === null));

  canSeeTravel$: Observable<boolean> = this._store.select(accountFeature.selectCanSeeTravel);

  canActivate(next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return combineLatest([this.contactPreferencesOn$, this.canSeeTravel$]).pipe(
      map(([contactPreferencesOn, canSeeTravel]) => {
        const isRegistrationPath = next.queryParamMap.get('registrationPath') === 'true';

        if (canSeeTravel && (!contactPreferencesOn || isRegistrationPath)) {
          return true;
        }

        return this._router.parseUrl(`${AppUrls.PREFERENCES}/${AppUrls.JOB_PREFERENCES}`);
      })
    );
  }
}
