import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AboutMeModel, IAdUserModel, IAdUserResponseModel, IProfessionalHierarchy, IRegistrationModel, IResetPasswordModel, IUserModel, NurseModel } from 'src/app/common';
import { environment } from 'src/environments/environment';
import { IJobPreferences, JobPreferenceReturn } from 'src/app/common/models/job-preference';
import { CommunicationPreferences } from 'src/app/common/models/communication-preferences';
import { IRegistrationSource } from '../common/contracts/registration-source';
import { map } from 'rxjs/operators';
import { DataDeletionResponse, DeletionCodeConfirmationRequest, DeletionCodeConfirmationResponse } from '../common';

@Injectable({
  providedIn: 'root'
})
export class UsersApiService {
  private httpOptions: { headers: HttpHeaders };

  constructor(private _http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': environment.ocpUsersApimSubscriptionKey
      })
    };
  }

  getUserState(): Observable<any> {
    return this._http.get<IUserModel>(`${environment.usersApiBaseUrl}/v1/user/state`, this.httpOptions);
  }

  getAccountState(): Observable<any> {
    return this._http.get<IUserModel>(`${environment.usersApiBaseUrl}/v1/user/account-state`, this.httpOptions);
  }

  postRegistration(input: IRegistrationModel): Observable<any> {
    return this._http.post<IUserModel>(`${environment.usersApiBaseUrl}/v1/register`, input, this.httpOptions);
  }

  postRegisterV2(input: IRegistrationModel): Observable<any> {
    return this._http.post<IUserModel>(`${environment.usersApiBaseUrl}/v2/register`, input, this.httpOptions);
  }

  getNurseState(): Observable<any> {
    return this._http.get<NurseModel>(`${environment.usersApiBaseUrl}/v1/user/me`, this.httpOptions);
  }

  getDefaultReferralRecruiter(): Observable<any> {
    return this._http.get(`${environment.usersApiBaseUrl}/v1/recruiter/default-referall`, this.httpOptions);
  }

  initializeUser(): Observable<any> {
    return this._http.post(`${environment.usersApiBaseUrl}/v1/user/initialize-appuser`, null, { ...this.httpOptions, observe: 'response' });
  }

  getPreferences(): Observable<any> {
    return this._http.get<JobPreferenceReturn>(`${environment.usersApiBaseUrl}/v1/job-preferences`, this.httpOptions);
  }

  postJobPreferences(input: any): Observable<any> {
    return this._http.post<IJobPreferences>(`${environment.usersApiBaseUrl}/v1/job-preferences`, input, this.httpOptions);
  }

  getCommunicationPreferences(): Observable<CommunicationPreferences> {
    return this._http.get<CommunicationPreferences>(`${environment.usersApiBaseUrl}/communication/v1/job-notifications`, {
      ...this.httpOptions,
      observe: 'body',
      responseType: 'json'
    });
  }

  postCommunicationPreferences(input: CommunicationPreferences): Observable<CommunicationPreferences> {
    return this._http.post<CommunicationPreferences>(`${environment.usersApiBaseUrl}/communication/v1/job-notifications`, input, {
      ...this.httpOptions,
      observe: 'body',
      responseType: 'json'
    });
  }

  createAdUser(input: IAdUserModel): Observable<any> {
    return this._http.post<IAdUserResponseModel>(`${environment.usersApiBaseUrl}/v1/create-ad-user`, input, this.httpOptions);
  }

  sendResetPasswordEmail(input: IResetPasswordModel): Observable<any> {
    return this._http.post(`${environment.usersApiBaseUrl}/v1/emails/resetpassword`, input, this.httpOptions);
  }

  trackRegistrationSource(input: IRegistrationSource): Observable<any> {
    return this._http.post(`${environment.usersApiBaseUrl}/v1/trackregistrationsource`, input, this.httpOptions);
  }

  registrationCheck(): Observable<number> {
    return this._http.post<number>(`${environment.usersApiBaseUrl}/v2/registration-check`, null, this.httpOptions);
  }

  getRegistration(): Observable<any> {
    return this._http.get<IRegistrationModel>(`${environment.usersApiBaseUrl}/v2/registration`, this.httpOptions);
  }

  getProfessionalHierarchy(): Observable<any> {
    return this._http.get<IProfessionalHierarchy>(`${environment.usersApiBaseUrl}/v1/lookups/professions-hierarchy`, this.httpOptions);
  }
  requestDeleteUser(): Observable<DataDeletionResponse> {
    return this._http
      .post<DataDeletionResponse>(`${environment.usersApiBaseUrl}/v1/request-user-delete`, null, { ...this.httpOptions, observe: 'response' })
      .pipe(map((resp: HttpResponse<DataDeletionResponse>) => resp.body));
  }
  confirmDeleteUser(input: DeletionCodeConfirmationRequest): Observable<DeletionCodeConfirmationResponse> {
    return this._http
      .post<DeletionCodeConfirmationResponse>(`${environment.usersApiBaseUrl}/v1/confirm-user-delete`, input, { ...this.httpOptions, observe: 'response' })
      .pipe(map((resp: HttpResponse<DeletionCodeConfirmationResponse>) => resp.body));
  }

  getFacilityApplications(): Observable<any> {
    return this._http.get<IProfessionalHierarchy>(`${environment.usersApiBaseUrl}/v1/facilities/applications`, this.httpOptions);
  }

  postFacilityApplication(facilityId: string): Observable<any> {
    return this._http.post<IProfessionalHierarchy>(`${environment.usersApiBaseUrl}/v1/facilities/applications`, { facilityId }, this.httpOptions);
  }

  updateAboutMe(model: AboutMeModel): Observable<any> {
    return this._http.put<any>(`${environment.usersApiBaseUrl}/v1/user/about-me`, model, this.httpOptions);
  }

  setVerificationType(verificationType: number): Observable<any> {
    return this._http.put<any>(`${environment.usersApiBaseUrl}/v1/verify/set-type`, { verificationType }, this.httpOptions);
  }

  checkVerificationKey(verificationKey: { emailKey?: string; smsKey?: number }): Observable<any> {
    return this._http.post<any>(`${environment.usersApiBaseUrl}/v1/verify/check`, verificationKey, this.httpOptions);
  }

  postSignIn(): Observable<any> {
    return this._http.patch<any>(`${environment.usersApiBaseUrl}/v1/user/login`, null, this.httpOptions);
  }
}
