import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { IAppState } from 'src/app/store/app/app.state';
import { selectHasFetchedArrivalsActivities, selectArrivalsActivitiesLoading, selectHasArrivalsActivities } from 'src/app/store/arrivals/arrivals.selectors';
import { AppUrls } from '../app-urls';

@Injectable()
export class ArrivalsActivitiesGuard implements CanActivate {
  constructor(
    private readonly _router: Router,
    private readonly _store: Store<IAppState>
  ) {}

  canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> | UrlTree {
    const arrivalsActivitiesFetched$: Observable<boolean> = this._store.select(selectHasFetchedArrivalsActivities);
    const arrivalsActivitiesLoading$: Observable<boolean> = this._store.select(selectArrivalsActivitiesLoading);
    const hasArrivalsActivities$: Observable<boolean> = this._store.select(selectHasArrivalsActivities);

    return combineLatest([arrivalsActivitiesFetched$, arrivalsActivitiesLoading$, hasArrivalsActivities$]).pipe(
      skipWhile(([activitiesFetched, activitiesLoading, _hasActivities]: [boolean, boolean, boolean]) => !activitiesFetched || activitiesLoading),
      map(([_activitiesFetched, _activitiesLoading, hasActivities]) => (hasActivities ? true : this._router.parseUrl(`/${AppUrls.DASHBOARD}`)))
    );
  }
}
