import { Component, OnDestroy, OnInit } from '@angular/core';
import { EnrollUserModel } from 'src/app/common/models/enroll-user-model';
import { UrlHelper } from 'src/app/common/UrlHelper';
import { AuthenticationService, NavHelper } from 'src/app/services';
import { AppInsights } from 'src/app/shared';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/app/app.state';
import { GetLookups, GetProfessionalHierarchy } from 'src/app/store/lookups/lookups.actions';
import {
  selectProfessionLookupMap,
  selectProfessionalHierarchy,
  selectProfessionalHierarchyLoading,
  selectRegistrationWorkExperienceMap,
  selectSpecialtyLookupMap
} from 'src/app/store/lookups/lookups.selectors';
import { filter, map, skipWhile, switchMap, take, takeUntil } from 'rxjs/operators';
import { combineLatest, from, Observable, of, Subject } from 'rxjs';
import { selectAdUserResultData, selectAdUserResultError, selectAdUserResultLoading } from 'src/app/store/userContext/userContext.selectors';
import { IAdUserResponseModel, ILookup, IProfessionalHierarchy, IRegistrationModel, IResetPasswordModel, TeamIds } from 'src/app/common';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from 'hc-design-system-lib';
import { ErrorMessages } from 'src/app/common/error-messages';
import { AppUrls } from 'src/app/app-urls';
import { selectFlagsLoading } from 'src/app/store/flags/flags.selectors';
import { getHierarchyNodeByType, getHierarchyTreeByName } from 'src/app/common/functions/dropdown-helpers';
import { IHierarchicalDropdownNode } from 'hc-design-system-lib/lib/components/form/form.interfaces';
import { PROFESSION_HIERARCHY_NODE_TYPES, PROFESSION_HIERARCHY_SECTORS } from 'src/app/common/constants';
import { SegmentReady } from 'src/app/store/segment/segment.actions';
import { UserContextActions } from 'src/app/store/userContext/userContext.actions';

@Component({
  selector: 'app-enroll',
  templateUrl: './enroll.component.html',
  styleUrls: ['./enroll.component.scss']
})
export class EnrollComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject<void>();

  get baseRedirectUri() {
    return window.location.origin;
  }
  get postSignUpRedirectUri() {
    return `${this.baseRedirectUri}/${AppUrls.REGISTRATION}`;
  }
  get postSignInRedirectUri() {
    return `${this.baseRedirectUri}/${AppUrls.DASHBOARD}`;
  }
  get postResetPasswordRedirectUri() {
    return `${this.baseRedirectUri}/${AppUrls.DASHBOARD}`;
  }

  availabilityData = ['1-5 Weeks', '6+ Weeks', 'Just browsing'];
  errorToastDuration = 5000;

  enrollmentForm: EnrollUserModel;
  selectedProfession: ILookup<string>;
  selectedSpecialty: ILookup<string>;
  registrationWorkExperience: ILookup<string>;
  startDateAvailability: string;

  professionLookup$ = this._store.select(selectProfessionLookupMap);
  specialtyLookup$ = this._store.select(selectSpecialtyLookupMap);
  registrationWorkExperienceLookup$ = this._store.select(selectRegistrationWorkExperienceMap);

  professionHierarchyLookup$ = this._store.select(selectProfessionalHierarchy);
  professionHierarchyLoading$ = this._store.select(selectProfessionalHierarchyLoading);
  flagsLoading$ = this._store.select(selectFlagsLoading);
  selectedProfessionHierarchy: IHierarchicalDropdownNode[];

  adUser$: Observable<IAdUserResponseModel> = this._store.select(selectAdUserResultData);
  adUserLoading$: Observable<boolean> = this._store.select(selectAdUserResultLoading);
  adUserError$: Observable<Error> = this._store.select(selectAdUserResultError);

  constructor(
    private _authService: AuthenticationService,
    private _appInsights: AppInsights,
    private _notificationService: NotificationService,
    private _navService: NavHelper,
    private _store: Store<IAppState>
  ) {
    Segment.ready(() => this._store.dispatch(new SegmentReady()));
    this._store.dispatch(new GetLookups());
    this._store.dispatch(new GetProfessionalHierarchy());
  }

  async ngOnInit() {
    combineLatest([this.flagsLoading$, this.professionHierarchyLoading$, this.professionHierarchyLookup$])
      .pipe(
        skipWhile(([flagsLoading, hierarchyLoading, professionHierarchy]) => flagsLoading || hierarchyLoading || professionHierarchy == null),
        switchMap(([_, __, professionHierarchy]) =>
          combineLatest([this.professionLookup$, this.specialtyLookup$, this.registrationWorkExperienceLookup$, of(professionHierarchy)])
        ),
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe(([professions, specialties, travelExperiences, hierarchy]) => {
        this.getDataFromQueryParams(professions, specialties, travelExperiences, hierarchy);
        try {
          this.validateQueryParams();
        } catch (e) {
          console.error(e);
          this._appInsights.trackException(e);
          this._authService.signUp(this.postSignUpRedirectUri);
          return;
        }

        this.createAdUser();
      });
  }

  async ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getDataFromQueryParams(
    professionLookup: ILookup<string>[],
    specialtyLookup: ILookup<string>[],
    registrationWorkExperienceLookup: ILookup<string>[],
    professionHierarchy?: IProfessionalHierarchy[]
  ): void {
    this.enrollmentForm = new EnrollUserModel();
    this.enrollmentForm.firstName = UrlHelper.GetFirstName();
    this.enrollmentForm.lastName = UrlHelper.GetLastName();
    this.enrollmentForm.phoneNumber = UrlHelper.GetPhoneNumber();
    this.enrollmentForm.email = UrlHelper.GetEmail();
    this.enrollmentForm.profession = UrlHelper.GetProfession();
    this.enrollmentForm.specialty = UrlHelper.GetSpecialty();
    this.enrollmentForm.pastExperience = UrlHelper.GetPastExperience();
    this.enrollmentForm.startDate = UrlHelper.GetStartDate();
    const isReferredStr = UrlHelper.GetIsReferred();
    this.enrollmentForm.isReferred = isReferredStr && isReferredStr === 'yes';
    this.enrollmentForm.referredBy = UrlHelper.GetReferredBy();
    this.enrollmentForm.recruiterId = UrlHelper.GetRecruiterId();
    const data = UrlHelper.getGACampaignData();
    if (data && data.size > 0) {
      this.enrollmentForm.campaign = data.get('utm_campaign');
      this.enrollmentForm.medium = data.get('utm_medium');
      this.enrollmentForm.source = data.get('utm_source');
    }

    this.registrationWorkExperience = registrationWorkExperienceLookup.find(w => w.name.toLowerCase() === this.enrollmentForm.pastExperience);
    this.startDateAvailability = this.availabilityData.find(x => x.toLowerCase() === this.enrollmentForm.startDate?.toLowerCase());

    this.getAlliedData(professionHierarchy);
  }

  getAlliedData(professionHierarchy: IProfessionalHierarchy[]) {
    const nursingHierarchy = professionHierarchy.find(s => s.name.toLowerCase() === PROFESSION_HIERARCHY_SECTORS.Nursing.toLowerCase())?.children;

    const nursingSelectedHierarchy = getHierarchyTreeByName(nursingHierarchy as IHierarchicalDropdownNode[], this.enrollmentForm.profession);
    const nonNursingSelectedHierarchy = getHierarchyTreeByName(professionHierarchy as IHierarchicalDropdownNode[], this.enrollmentForm.profession);
    if (nursingSelectedHierarchy) {
      this.selectedProfessionHierarchy = nursingSelectedHierarchy;
      this.enrollmentForm.professionSector = PROFESSION_HIERARCHY_SECTORS.Nursing;
    } else if (nonNursingSelectedHierarchy) {
      this.selectedProfessionHierarchy = nonNursingSelectedHierarchy;
      this.enrollmentForm.professionSector = PROFESSION_HIERARCHY_SECTORS.Allied;
    }

    if (this.selectedProfessionHierarchy?.length) {
      let profession = getHierarchyNodeByType(this.selectedProfessionHierarchy[0], PROFESSION_HIERARCHY_NODE_TYPES.Profession);
      if (profession) {
        this.selectedProfession = {
          id: profession.id,
          name: profession.name,
          requiresSpecialty: profession.children?.some(s => s && s.type.toLowerCase() === PROFESSION_HIERARCHY_NODE_TYPES.Specialty.toLowerCase())
        } as ILookup<string>;
      }

      let specialtyHierarchy = getHierarchyTreeByName(this.selectedProfessionHierarchy, this.enrollmentForm.specialty);
      if (specialtyHierarchy?.length) {
        let specialty = getHierarchyNodeByType(specialtyHierarchy[0], PROFESSION_HIERARCHY_NODE_TYPES.Specialty);
        this.selectedSpecialty = {
          id: specialty.id,
          name: specialty.name
        } as ILookup<string>;
      }
    }
  }

  validateQueryParams(): void {
    const { missingFields, profession, specialty } = this.enrollmentForm;
    const missingParamEventPrefix = 'enroll.missingQueryParameters';
    const badParamEventPrefix = 'enroll.badQueryParameters';

    if (missingFields.length) {
      throw new Error(`${missingParamEventPrefix} \n\n The following query parameters are missing: \n ${missingFields.join('\n\t')}`);
    }

    if (!this.selectedProfession) {
      throw new Error(`${badParamEventPrefix}.profession \n\n The informed profession (${profession}) could not be found on the lookup.`);
    }

    if (this.selectedProfession.requiresSpecialty && specialty && !this.selectedSpecialty) {
      throw new Error(`${badParamEventPrefix}.specialty \n\n The informed specialty (${specialty}) could not be found on the lookup.`);
    }

    if (!this.registrationWorkExperience) {
      throw new Error(`${badParamEventPrefix}.pastExperience \n\n The informed past experience (${this.enrollmentForm.pastExperience}) could not be found on the lookup.`);
    }

    if (!this.startDateAvailability) {
      throw new Error(`${badParamEventPrefix}.startDate \n\n The informed start date (${this.enrollmentForm.startDate}) is invalid.`);
    }
  }

  createAdUser(): void {
    const newAdUser = UserContextActions.createAdUser({
      email: this.enrollmentForm.email,
      firstName: this.enrollmentForm.firstName,
      lastName: this.enrollmentForm.lastName,
      generateToken: true
    });
    this._store.dispatch(newAdUser);
    combineLatest([this.adUser$, this.adUserLoading$, this.adUserError$])
      .pipe(
        filter(([adUser, adUserLoading, adUserError]) => !adUserLoading && !(adUser == null && adUserError == null)),
        map(([adUser, adUserLoading, adUserError]: [IAdUserResponseModel, boolean, Error]) => [adUser, adUserError]),
        takeUntil(this.destroy$)
      )
      .subscribe(([adUser, adUserError]: [IAdUserResponseModel, Error]) => {
        if (adUser && adUser.success) {
          if (adUser.token) {
            from(
              this._authService.buildAuthResultFromToken(adUser.token).then(() => {
                //short-circuit here if isExistingHCINUser and then redirect
                if (adUser.isExistingHCINUser) {
                  this._navService.goToLoginError();
                  return;
                }
                this.postRegistration();
                this.sendResetPasswordEmail();

                let searchFilters = `profession=${this.selectedProfession.id}`;
                if (this.selectedSpecialty) {
                  searchFilters += `&specialty=${this.selectedSpecialty.id}`;
                }
                this._navService.goToJobSearchFilters(searchFilters);
              })
            );
          } else {
            this._authService.passwordReset(this.postResetPasswordRedirectUri, this.enrollmentForm.email);
          }
        } else if (adUser && !adUser.success) {
          this._authService.signUp(this.postSignUpRedirectUri, this.enrollmentForm.email);
        }

        if (adUserError) {
          const statusCode = (adUserError as HttpErrorResponse).status;
          switch (statusCode) {
            case 400:
              this._navService.redirectToMarketingSignUp(this.enrollmentForm.email, this.enrollmentForm.firstName, this.enrollmentForm.lastName);
              break;
            case 409:
              this._notificationService.showNotification(ErrorMessages.accountExists, 'error', this.errorToastDuration);
              setTimeout(() => {
                this._authService.login(this.postSignInRedirectUri, this.enrollmentForm.email);
              }, this.errorToastDuration);
              break;
            case 500:
            default:
              // timeout allows http intercepter to display generic error
              setTimeout(() => {
                this._authService.signUp(this.postSignUpRedirectUri, this.enrollmentForm.email);
              }, this.errorToastDuration);
          }
        }
      });
  }

  postRegistration() {
    const model: IRegistrationModel = {
      email: this.enrollmentForm.email,
      firstName: this.enrollmentForm.firstName,
      lastName: this.enrollmentForm.lastName,
      profession: this.selectedProfession.id,
      specialtyDto: this.selectedSpecialty,
      startDate: this.startDateAvailability,
      mobilePhone: this.enrollmentForm.phoneNumber.replace(new RegExp('\\D', 'g'), '').slice(-10),
      teamId: TeamIds.HCTN,
      referralStatus: this.enrollmentForm.isReferred,
      referralText: this.enrollmentForm.referredBy,
      travelExperience: +this.registrationWorkExperience.id,
      recruiter: null,
      campaign: this.enrollmentForm.campaign,
      medium: this.enrollmentForm.medium,
      source: this.enrollmentForm.source,
      recruiterId: this.enrollmentForm.recruiterId
    };

    model.professionSector = this.enrollmentForm.professionSector;

    this._store.dispatch(UserContextActions.submitRegistration(model));
  }

  sendResetPasswordEmail() {
    const model: IResetPasswordModel = {
      email: this.enrollmentForm.email
    };
    this._store.dispatch(UserContextActions.sendResetPasswordEmail(model));
  }
}
