<ion-app>
  <ng-progress *ngIf="loaded()"></ng-progress>
  <div class="viewport">
    <app-no-connection class="viewport" *ngIf="noConnection()"></app-no-connection>
    <div class="loading-image-area" *ngIf="!loaded()">
      <mat-progress-spinner color="accent" mode="indeterminate"></mat-progress-spinner>
      <div class="message"></div>
    </div>
    <div fxFlex class="viewport" fxLayout="column">
      <router-outlet class="router-flex"></router-outlet>
    </div>
  </div>
</ion-app>
