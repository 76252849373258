import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavHelper } from 'src/app/services';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import {
  selectNurseData,
  selectVerificationCheckResultError,
  selectVerificationCheckResultLoading,
  selectVerificationEmailSentLoading
} from 'src/app/store/userContext/userContext.selectors';
import { filter, take, takeUntil } from 'rxjs/operators';
import { NurseModel } from 'src/app/common';
import { UserContextActions } from 'src/app/store/userContext/userContext.actions';

@Component({
  selector: 'app-verify-check',
  templateUrl: './verify-check.component.html',
  styleUrls: ['./verify-check.component.scss']
})
export class VerifyCheckComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  userEmail: string;

  nurse$: Observable<NurseModel> = this.store.select(selectNurseData);
  verificationCheckLoading$ = this.store.select(selectVerificationCheckResultLoading);
  verificationCheckResultError$ = this.store.select(selectVerificationCheckResultError);

  constructor(
    private _route: ActivatedRoute,
    private _navHelper: NavHelper,
    public snackBar: MatSnackBar,
    private store: Store
  ) {}

  ngOnInit() {
    this.nurse$
      .pipe(
        filter(nurse => nurse !== null),
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe((nurseData: NurseModel) => {
        if (!nurseData.isVerified) {
          const key = this._route.snapshot.queryParams['key'];
          if (key !== null && key !== undefined) {
            this.store.dispatch(UserContextActions.checkVerificationKey({ emailKey: key }));
          }
        } else {
          this._navHelper.goToDashBoard();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  navigateToVerifySupport() {
    this._navHelper.goToVerifySupport();
  }

  backToSignIn() {
    this.store.dispatch(UserContextActions.clearCacheAndLogOut());
  }

  resendEmail() {
    this.store.dispatch(UserContextActions.sendVerificationEmail(true));

    this.store
      .select(selectVerificationEmailSentLoading)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this._navHelper.goToVerifyUserEmail();
      });
  }
}
