<ng-container
  *ngIf="{
    arrivals: arrivals$ | async
  } as obs"
>
  <hc-tile-card *ngIf="obs?.arrivals" [cardConfig]="tileConfig" [templateReference]="arrivalsTemplate"></hc-tile-card>

  <ng-template #arrivalsTemplate>
    <div class="status-banner" fxLayout="row">Deployment Date: {{ obs?.arrivals?.confirmedDeploymentDate ?? 'TBD' }}</div>

    <div class="arrivals-tile" fxLayout="column">
      <div class="arrivals-flight-details" fxLayout="row"><mat-icon>airplanemode_active</mat-icon>{{ flightDetails }}</div>
      <div fxLayout="row" fxLayout.lt-md="column">
        <div class="arrivals-coordinator-details" fxFlex="80"><mat-icon>person</mat-icon>Arrival Coordinator: {{ obs?.arrivals?.arrivalContractor ?? 'TBD' }}</div>
        <div class="arrivals-buttons" fxFlex>
          <hc-button id="navigateToArrivalDetails" (buttonClick)="nagivateToArrivalDetails()">View Arrival Details</hc-button>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
