import { createActionGroup, emptyProps } from '@ngrx/store';
import {
  AboutMeModel,
  CandidateQualification,
  DataDeletionResponse,
  DeletionCodeConfirmationResponse,
  HcinContactModel,
  IAdUserModel,
  IAdUserResponseModel,
  IRegistrationModel,
  IRegistrationResponseModel,
  IResetPasswordModel,
  IUserModel,
  NurseModel,
  NurseProfileModel,
  PreEmploymentQuestionnaire,
  ProfessionalOverviewModel,
  TaskCompletionReturnObject,
  UserContextIN
} from 'src/app/common';
import { VerificationStatus } from 'src/app/common/constants';
import { CommunicationPreferences } from 'src/app/common/models/communication-preferences';
import { GenericCompletionReturnObject } from 'src/app/common/models/generic-completion-return-model';

export const UserContextActions = createActionGroup({
  source: 'USER_CONTEXT',
  events: {
    'nuking state': emptyProps(),

    'get user': emptyProps(),

    'get user success': (payload: IUserModel) => ({ payload }),

    'get user error': (error: Error) => ({ error }),

    'get user hcin': emptyProps(),

    'get user hcin success': (payload: UserContextIN) => ({ payload }),

    'get user hcin error': (error: Error) => ({ error }),

    'create AD user': (payload: IAdUserModel) => ({ payload }),

    'create AD user success': (payload: IAdUserResponseModel) => ({ payload }),

    'create AD user error': (error: Error) => ({ error }),

    'set user job notifications active': (payload: boolean) => ({ payload }),

    'get nurse': emptyProps(),

    'get nurse success': (payload: NurseModel) => ({ payload }),

    'get nurse error': (error: Error) => ({ error }),

    'get international nurse': emptyProps(),

    'get international nurse success': (payload: HcinContactModel) => ({ payload }),

    'get international nurse error': (error: Error) => ({ error }),

    'set nurse is new status': (payload: boolean) => ({ payload }),

    'submit registration': (payload: IRegistrationModel) => ({ payload }),

    'submit registration success': (payload: IRegistrationResponseModel) => ({ payload }),

    'submit registration error': (error: Error) => ({ error }),

    'unlock registration': emptyProps(),

    'unlock registration success': emptyProps(),

    'unlock registration error': (error: Error) => ({ error }),

    'get registration': emptyProps(),

    'get registration success': (payload: IRegistrationModel) => ({ payload }),

    'get registration error': (error: Error) => ({ error }),

    'set nurse profilePicture': (payload: string) => ({ payload }),

    'set nurse is verified': (payload: boolean) => ({ payload }),

    'set user is new to false': emptyProps(),

    'reset user': emptyProps(),

    'reset user success': (response: any) => ({ response }),

    'reset user error': (error: Error) => ({ error }),

    'post sign in': emptyProps(),

    'post sign in success': (response: any) => ({ response }),

    'post sign in error': (error: Error) => ({ error }),

    'clear cache': emptyProps(),

    'clear cache success': (response: any) => ({ response }),

    'clear cache error': (error: Error) => ({ error }),

    'clear cache and log out': emptyProps(),

    'clear cache and log out success': (response: any) => ({ response }),

    'clear cache and log out error': (error: Error) => ({ error }),

    'log out user': emptyProps(),

    'log out user success': (response: any) => ({ response }),

    'log out user error': (error: Error) => ({ error }),

    'reload window': emptyProps(),

    'contact select reset': (request: any) => ({ request }),

    'contact select reset success': (response: any) => ({ response }),

    'contact select reset error': (error: Error) => ({ error }),

    'initialize user': emptyProps(),

    'initialize user success': (isNewUser: boolean, email: string) => ({ isNewUser, email }),

    'initialize user error': (error: Error) => ({ error }),

    'set is referred': (isReferred: boolean) => ({ isReferred }),

    'get professional overview': emptyProps(),

    'get professional overview success': (profileData: NurseProfileModel) => ({ profileData }),

    'get professional overview error': (error: Error) => ({ error }),

    'update summary data': (summaryData: ProfessionalOverviewModel) => ({ summaryData }),

    'update summary success': (summaryData: TaskCompletionReturnObject) => ({ summaryData }),

    'update summary error': (error: Error) => ({ error }),

    'update summary reset': emptyProps(),

    'get summary prompts': emptyProps(),

    'get summary prompts success': (summaryPrompts: string[]) => ({ summaryPrompts }),

    'get summary prompts error': (error: Error) => ({ error }),

    'send reset password email': (payload: IResetPasswordModel) => ({ payload }),

    'send reset password email success': emptyProps(),

    'send reset password email error': (error: Error) => ({ error }),

    'get questionnaire': emptyProps(),

    'get questionnaire success': (data: PreEmploymentQuestionnaire) => ({ data }),

    'get questionnaire error': (error: Error) => ({ error }),

    'update questionnaire': (data: PreEmploymentQuestionnaire) => ({ data }),

    'update questionnaire success': (response: GenericCompletionReturnObject<number>, data: PreEmploymentQuestionnaire) => ({ response, data }),

    'update questionnaire error': (error: Error) => ({ error }),

    'update questionnaire reset': emptyProps(),

    'get about me': emptyProps(),

    'get about me success': (payload: AboutMeModel) => ({ payload }),

    'get about me error': (error: Error) => ({ error }),

    'update about me': (payload: AboutMeModel) => ({ payload }),

    'update about me success': (response: TaskCompletionReturnObject) => ({ response }),

    'update about me error': (error: Error) => ({ error }),

    'update about me reset': emptyProps(),

    'delete qualification': (payload: string) => ({ payload }),

    'upload profile picture': (files: File[]) => ({ files }),

    'upload profile picture success': (payload: any) => ({ payload }),

    'upload profile picture error': (error: Error) => ({ error }),

    'get documents': emptyProps(),

    'get documents success': (payload: CandidateQualification[]) => ({ payload }),

    'get documents error': (error: Error) => ({ error }),

    'delete documents': (payload: CandidateQualification) => ({ payload }),

    'delete documents success': (payload: CandidateQualification) => ({ payload }),

    'delete documents error': (error: Error) => ({ error }),

    'get documents by id': (payload: CandidateQualification) => ({ payload }),

    'get documents by id success': (payload: Response) => ({ payload }),

    'get documents by id error': (error: Error) => ({ error }),

    'bulk upload documents': (payload: { files: File[] }) => ({ payload }),

    'bulk upload documents success': emptyProps(),

    'bulk upload documents error': (error: Error) => ({ error }),

    'set show specialty popup': (showSpecialtyPopup: boolean) => ({ showSpecialtyPopup }),

    'send verification email': (resend?: boolean) => ({ resend }),

    'send verification email success': (userEmail: string) => ({ userEmail }),

    'send verification email error': (error: Error) => ({ error }),

    'send verification sms': (resend?: boolean) => ({ resend }),

    'send verification sms success': (userPhoneNumber: string, response: number, resend?: boolean) => ({ userPhoneNumber, response, resend }),

    'send verification sms error': (error: Error) => ({ error }),

    'set verification type': (verificationType: number) => ({ verificationType }),

    'set verification type success': (response: number, verificationType: number) => ({ response, verificationType }),

    'set verification type error': (error: Error) => ({ error }),

    'check verification key': (verificationKey: { emailKey?: string; smsKey?: number }) => ({ verificationKey }),

    'check verification key success': (response: { message: string; status: VerificationStatus }) => ({ response }),

    'check verification key error': (error: Error) => ({ error }),

    'send verification support email': (supportReport: { name: string; email: string; description: string }) => ({ supportReport }),

    'send verification support email success': emptyProps(),

    'send verification support email error': (error: Error) => ({ error }),

    'delete user requested': emptyProps(),

    'delete user request success': (payload: DataDeletionResponse) => ({ payload }),

    'delete user request error': (error: Error) => ({ error }),

    'delete user': (payload: { confirmationCode: string }) => ({ payload }),

    'delete user success': (payload: DeletionCodeConfirmationResponse) => ({ payload }),

    'delete user error': (error: Error) => ({ error }),

    'get contact preferences': emptyProps(),

    'get contact preferences success': (payload: CommunicationPreferences) => ({ payload }),

    'get contact preferences error': (error: Error) => ({ error }),

    'update contact preferences': (payload: CommunicationPreferences) => ({ payload }),

    'update contact preferences success': (payload: CommunicationPreferences) => ({ payload }),

    'update contact preferences error': (error: Error) => ({ error })
  }
});
