import { ArrivalsActions, EArrivalsActions } from './arrivals.actions';
import { IArrivalsState, initialArrivalsState } from './arrivals.state';
import { ArrivalsActivity } from '../../common/models/arrivals';

export function arrivalsReducer(state = initialArrivalsState, action: ArrivalsActions): IArrivalsState {
  switch (action.type) {
    case EArrivalsActions.GetArrivals:
      return {
        ...state,
        arrivals: {
          data: null,
          loading: true,
          error: null,
          hasFetched: false
        }
      };
    case EArrivalsActions.GetArrivalsSuccess:
      return {
        ...state,
        arrivals: {
          data: action.payload,
          loading: false,
          error: null,
          hasFetched: true
        }
      };
    case EArrivalsActions.GetArrivalsError:
      return {
        ...state,
        arrivals: {
          data: null,
          loading: false,
          error: action.error,
          hasFetched: true
        }
      };

    case EArrivalsActions.GetArrivalsActivities:
      return {
        ...state,
        arrivalsActivities: {
          data: null,
          loading: true,
          error: null,
          hasFetched: false
        }
      };

    case EArrivalsActions.GetArrivalsActivitiesSuccess:
      return {
        ...state,
        arrivalsActivities: {
          data: action.payload,
          loading: false,
          error: null,
          hasFetched: true
        }
      };

    case EArrivalsActions.GetArrivalsActivitiesError:
      return {
        ...state,
        arrivalsActivities: {
          data: null,
          loading: false,
          error: action.error,
          hasFetched: true
        }
      };

    case EArrivalsActions.UpdateArrivalsActivity:
      return {
        ...state,
        updateArrivalsActityResult: {
          data: action.payload,
          loading: true,
          error: null
        }
      };

    case EArrivalsActions.UpdateArrivalsActivitySuccess:
      return {
        ...state,
        arrivalsActivities: {
          ...state.arrivalsActivities,
          data: state.arrivalsActivities.data?.map((activity: ArrivalsActivity) => (activity.id === action.response?.id ? { ...activity, ...action.response } : activity))
        },
        updateArrivalsActityResult: {
          data: null,
          loading: false,
          error: null
        }
      };

    case EArrivalsActions.UpdateArrivalsActivityError:
      return {
        ...state,
        updateArrivalsActityResult: {
          data: null,
          loading: false,
          error: action.error
        }
      };
    default:
      return state;
  }
}
